import styled from 'styled-components'

export const CommentChatContainer = styled.div`
  color: #242731;

  .comments-header {
    padding-bottom: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .comment-input-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .comment-list {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .comment-card {
      display: flex;
      gap: 16px;
      flex-direction: column;
      .identification-row {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: #d9d9d933;
        padding: 8px;
        border-radius: 8px;
        .date-time {
          font-size: 10px;
        }
      }
      .text-row {
        padding-left: 66px;
      }
    }
  }
`
