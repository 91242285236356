import React, { useState, useEffect } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { LineChartContainer } from './styles'

export interface ILineSeries {
  name: string
  data: number[]
}

interface IProps {
  title: string
  yTitle: string
  series: ILineSeries[]
}

export const MonthLineChart: React.FC<IProps> = ({ series, title, yTitle }) => {
  const [options, setOptions] = useState({})

  useEffect(() => {
    setOptions({
      title: {
        text: title
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis: {
        title: {
          text: yTitle
        }
      },
      series
    })
  }, [series, title, yTitle])

  return (
    <LineChartContainer>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </LineChartContainer>
  )
}
