class StorageService {
  public hasItem(key: string): boolean {
    return this.getItem(key) != null
  }

  public getItem<T>(key: string): T {
    try {
      const value = localStorage.getItem(key)

      if (value) {
        return JSON.parse(value)
      }
    } catch (e) {
      console.error(e)

      return null
    }
  }

  public setItem<T>(key: string, value: T): void {
    try {
      if (value != null) {
        localStorage.setItem(key, JSON.stringify(value))
      } else {
        localStorage.removeItem(key)
      }
    } catch (e) {
      console.error(e)
    }
  }

  public removeItem(key: string): void {
    try {
      this.setItem(key, null)
    } catch (e) {
      console.error(e)
    }
  }
}

export const storageService = new StorageService()
