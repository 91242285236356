import { CLASSIFICACAO, api } from 'sigap/utils/constants'

interface ClassificacaoPostReturn {
  id_classificacao: number
}

export async function listClassificacao(): Promise<IClassificacao[]> {
  const { data: response } = await api.get(`/${CLASSIFICACAO}`)

  return response
}

export async function insertClassificacao(classificacao: IClassificacao): Promise<ClassificacaoPostReturn> {
  const { data: response } = await api.post(`/${CLASSIFICACAO}`, classificacao)

  return response
}
