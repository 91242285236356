export const returnStringFromBasicInfoArray = (array: IBasicInfo[]): string => {
  const arrayToJoin: string[] = []
  try {
    array.map((el) => arrayToJoin.push(el.nome))

    return arrayToJoin.join(', ')
  } catch (e) {
    return ''
  }
}
