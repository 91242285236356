import { BiBarChartSquare } from 'react-icons/bi'
import { CgClipboard } from 'react-icons/cg'
import { FaRegFile } from 'react-icons/fa'
import { MdDashboard } from 'react-icons/md'
import { RiCalendarCheckFill } from 'react-icons/ri'
import { TbUsers } from 'react-icons/tb'
import { BsFillKanbanFill } from "react-icons/bs";
import { AiOutlineProject } from "react-icons/ai";
import { GiProgression } from "react-icons/gi";
import { FaFileContract } from "react-icons/fa";

export const routes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: MdDashboard
  },
  {
    name: 'Projetos',
    path: '/projetos',
    icon: AiOutlineProject
  },
  {
    name: 'Contratos',
    path: '/contratos',
    icon: FaFileContract
  },
  {
    name: 'Demandas',
    path: '/demandas',
    icon: GiProgression
  },
  {
    name: 'Atividades',
    path: '/tarefas',
    icon: BsFillKanbanFill
  }
]
