import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { ModalOverlay, ModalContent, ModalHeader, ModalBody, Input, ModalFooter, useToast } from '@chakra-ui/react'

import { editSubsecretaria, insertSubsecretaria } from 'sigap/services/subsecretaria'
import { USER_CREATE_TOAST_MESSAGE, USER_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'

interface IFormData {
  nome: string
}

interface IProps {
  subsecretariaModalData: ISubsecretaria
  showSubsecretariaModal: boolean
  setSubsecretariaModalData: React.Dispatch<React.SetStateAction<ISubsecretaria>>
  setShowSubsecretariaModal: React.Dispatch<React.SetStateAction<boolean>>
  refreshSubsecretarias: () => void
}

export const SubsecretariaModal: React.FC<IProps> = ({
  refreshSubsecretarias,
  setShowSubsecretariaModal,
  setSubsecretariaModalData,
  showSubsecretariaModal,
  subsecretariaModalData
}) => {
  const [requestLoading, setRequestLoading] = useState<boolean>(false)

  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()

  const currentFormData = watch()

  const toast = useToast()

  const isFormComplete = useMemo(() => currentFormData.nome?.length > 0, [currentFormData])

  const handleClose = (): void => {
    setShowSubsecretariaModal(false)
    setSubsecretariaModalData(null)
    setValue('nome', '')
  }

  const handleEditSubsec = async (formObject: IFormData): Promise<void> => {
    setRequestLoading(true)
    const subsecretariaObj: ISubsecretaria = {
      nome: formObject.nome,
      id: subsecretariaModalData.id
    }
    await editSubsecretaria(subsecretariaObj, subsecretariaModalData.id)
    setShowSubsecretariaModal(false)
    refreshSubsecretarias()
  }

  const handleCreateSubsec = async (formObject: IFormData): Promise<void> => {
    setRequestLoading(true)
    const subsecretariaObj: ISubsecretaria = {
      nome: formObject.nome
    }
    await insertSubsecretaria(subsecretariaObj)
    setShowSubsecretariaModal(false)
    refreshSubsecretarias()
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleEditSubsec(formObject), USER_EDIT_TOAST_MESSAGE)
  }

  const handleClickSubmitCreate = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleCreateSubsec(formObject), USER_CREATE_TOAST_MESSAGE)
  }

  useEffect(() => {
    if (subsecretariaModalData) {
      setValue('nome', subsecretariaModalData.nome)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsecretariaModalData])

  return (
    <ModalContainer isOpen={showSubsecretariaModal} onClose={console.log}>
      <ModalOverlay />
      <ModalContent minWidth={800} className="nova-meta-modal-content">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          className="nova-meta-modal-header"
          color={'gray.900'}
          fontFamily={'Poppins'}
          fontSize={32}
          fontStyle={'normal'}
          fontWeight={700}
          lineHeight={'36px'}
        >
          Subsecretaria
        </ModalHeader>
        <ModalBody
          // display={'grid'}
          padding={'0px 48px'}
          // gap={'32px'}
          className="nova-meta-modal-body"
          // gridTemplateColumns={'1fr 1fr'}
        >
          <div className="label-input-bundle">
            <label>Nome</label>
            <Controller name="nome" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
        </ModalBody>
        <ModalFooter display={'flex'} gap={'80px'}>
          {subsecretariaModalData ? (
            <GenericButton onClick={handleSubmit(handleClickSubmitEdit)} isDisabled={!isFormComplete}>
              Salvar Edição
            </GenericButton>
          ) : (
            <GenericButton onClick={handleSubmit(handleClickSubmitCreate)} isDisabled={!isFormComplete}>
              Adicionar e Salvar
            </GenericButton>
          )}

          <GenericButton variant={'outline'} onClick={handleClose} color={'#242731'}>
            Cancelar
          </GenericButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
