/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'

import { Button, ButtonProps } from '@chakra-ui/react'

export const GenericButton = forwardRef<HTMLInputElement, ButtonProps>((props, forwardRef) => {
  return (
    <Button
      {...props}
      ref={forwardRef}
      // backgroundColor={props.backgroundColor ?? '#24aae1'}
      // color={props.color ?? '#242731'}
      colorScheme={props.colorScheme ?? 'blue'}
      fontSize={props.fontSize ?? '16px'}
      fontStyle={props.fontStyle ?? 'normal'}
      fontWeight={props.fontWeight ?? 500}
      lineHeight={props.lineHeight ?? '24px'}
      // border={props.border ?? '1px solid black'}
    />
  )
})
