import React from 'react'

import { NoDataComponentContainer } from './styles'

interface IProps {
  dataType: string
}

export const NoDataComponent: React.FC<IProps> = ({ dataType }) => {
  return <NoDataComponentContainer>{`Não há ${dataType}.`}</NoDataComponentContainer>
}
