import { lighten, shade } from 'polished'

export const colors = {
  'blue-100': lighten(0.4, '#005A92'),
  'blue-200': lighten(0.3, '#005A92'),
  'blue-300': lighten(0.2, '#005A92'),
  'blue-400': lighten(0.1, '#005A92'),
  'blue-500': '#005A92',
  'blue-600': shade(0.1, '#005A92'),
  'blue-700': shade(0.2, '#005A92'),
  'blue-800': shade(0.3, '#005A92'),
  'blue-900': shade(0.4, '#005A92'),
  'pef-blue-1': '#ccecfb',
  'pef-blue-2': '#91d8f7',
  'pef-blue-3': '#24aae1',
  'pef-gray': '#283345',
  'pef-orange': '#f58634',
  'green-100': lighten(0.4, '#427842'),
  'green-200': lighten(0.3, '#427842'),
  'green-300': lighten(0.2, '#427842'),
  'green-400': lighten(0.1, '#427842'),
  'green-500': '#427842',
  'green-600': shade(0.1, '#427842'),
  'green-700': shade(0.2, '#427842'),
  'green-800': shade(0.3, '#427842'),
  'green-900': shade(0.4, '#427842'),
  'yellow-100': lighten(0.4, '#BC9D32'),
  'yellow-200': lighten(0.3, '#BC9D32'),
  'yellow-300': lighten(0.2, '#BC9D32'),
  'yellow-400': lighten(0.1, '#BC9D32'),
  'yellow-500': '#BC9D32',
  'yellow-600': shade(0.1, '#BC9D32'),
  'yellow-700': shade(0.2, '#BC9D32'),
  'yellow-800': shade(0.3, '#BC9D32'),
  'yellow-900': shade(0.4, '#BC9D32'),
  'gray-100': lighten(0.4, '#A0A0A0'),
  'gray-200': lighten(0.3, '#A0A0A0'),
  'gray-300': lighten(0.2, '#A0A0A0'),
  'gray-400': lighten(0.1, '#A0A0A0'),
  'gray-500': '#A0A0A0',
  'gray-600': shade(0.1, '#A0A0A0'),
  'gray-700': shade(0.2, '#A0A0A0'),
  'gray-800': shade(0.3, '#A0A0A0'),
  'gray-900': shade(0.4, '#A0A0A0'),
  'gray-1000': '#000000'
}
