import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Input, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/react'

import { editIndicadores, insertIndicadores } from 'sigap/services/indicadores'
import { IIndicador } from 'sigap/types/projects'
import {
  INDICADOR_CREATE_TOAST_MESSAGE,
  INDICADOR_EDIT_TOAST_MESSAGE
} from 'sigap/utils/constants/requestToastMessages'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { NumberMaskInput } from 'sigap/utils/generic/MaskInput/MaskInput'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'
import { formatCurrencyToNumber } from 'sigap/utils/methods/formatCurrencyToNumber'

const periodicidadeOptions: Option<number>[] = [
  { label: 'Anual', value: 1 },
  { label: 'Mensal', value: 2 },
  { label: 'Bimestral', value: 3 },
  { label: 'Trimestral', value: 4 },
  { label: 'Quadrimestral', value: 5 },
  { label: 'Semestral', value: 6 }
]

const defaultForm: IFormData = {
  nome: '',
  atual: '',
  meta: '',
  linha_base: '',
  periodicidade: undefined
}

interface IFormData {
  nome: string
  atual: string
  meta: string
  linha_base: string
  periodicidade: Option<number>
}

interface IProps {
  showNovoIndicadorModal: boolean
  setShowNovoIndicadorModal: (k: boolean) => void
  projetoId: number
  indicadorModalData?: IIndicador
  setIndicadorModalData: React.Dispatch<React.SetStateAction<IIndicador>>
  refreshIndicadores: () => Promise<IIndicador[]>

  setTempIndicadores?: React.Dispatch<React.SetStateAction<IIndicador[]>>
  tempIndicadores?: IIndicador[]
  isNewProjectScreen?: boolean
}

export const ModalNovoIndicador: React.FC<IProps> = ({
  showNovoIndicadorModal,
  setShowNovoIndicadorModal,
  projetoId,
  indicadorModalData,

  setIndicadorModalData,
  refreshIndicadores,
  isNewProjectScreen,
  setTempIndicadores,
  tempIndicadores
}) => {
  const [count, setCount] = useState<number>(1)

  const { handleSubmit, control, setValue, watch } = useForm<IFormData>({ mode: 'all', defaultValues: defaultForm })

  const toast = useToast()

  const currentFormObject = watch()

  const isFormComplete = useMemo(
    () =>
      currentFormObject.atual != null &&
      currentFormObject.meta != null &&
      currentFormObject.periodicidade != null &&
      currentFormObject.nome?.length > 0,
    [currentFormObject]
  )

  const normalizeIndicadorObject = (formObject: IFormData): IIndicador => {
    const normalizedTempIndicadorObject: IIndicador = {
      nome: formObject.nome,
      atual: formatCurrencyToNumber(formObject.atual),
      linha_base: formatCurrencyToNumber(formObject.atual),
      meta: formatCurrencyToNumber(formObject.meta),
      periodicidade: formObject.periodicidade?.value,

      id_indicador: indicadorModalData?.id_indicador
    }
    setCount(count + 1)

    return normalizedTempIndicadorObject
  }

  const handleCleanForm = (): void => {
    setValue('atual', undefined)
    setValue('linha_base', undefined)
    setValue('meta', undefined)
    setValue('nome', '')
    setValue('periodicidade', undefined)
  }
  const handleCloseNovoIndicadorModal = (): void => {
    handleCleanForm()
    setIndicadorModalData(undefined)
    setShowNovoIndicadorModal(false)
  }

  const handleCreateIndicador = async (formObject: IFormData): Promise<void> => {
    const normalizedTempIndicador = normalizeIndicadorObject(formObject)
    await insertIndicadores({ indicadores: [normalizedTempIndicador] }, projetoId)
    refreshIndicadores()
    handleCloseNovoIndicadorModal()
  }

  const handleClickSubmitCreate = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleCreateIndicador(formObject), INDICADOR_CREATE_TOAST_MESSAGE)
  }

  const handleEditIndicador = async (formObject: IFormData): Promise<void> => {
    const normalizedTempIndicador = normalizeIndicadorObject(formObject)
    await editIndicadores({ indicadores: [normalizedTempIndicador] }, projetoId)
    refreshIndicadores()
    handleCloseNovoIndicadorModal()
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleEditIndicador(formObject), INDICADOR_EDIT_TOAST_MESSAGE)
  }

  const normalizeTempIndicadorObject = (formObject: IFormData): IIndicador => {
    const normalizedTempIndicadorObject: IIndicador = {
      nome: formObject.nome,
      atual: formatCurrencyToNumber(formObject.atual),
      linha_base: formatCurrencyToNumber(formObject.atual),
      meta: formatCurrencyToNumber(formObject.meta),
      periodicidade: formObject.periodicidade?.value,
      id: count,
      id_indicador: indicadorModalData?.id_indicador
    }
    setCount(count + 1)

    return normalizedTempIndicadorObject
  }

  const handleNovoTempIndicador = (formObject: IFormData): void => {
    const normalizedTempIndicador = normalizeTempIndicadorObject(formObject)
    setTempIndicadores((current) => [...current, normalizedTempIndicador])
    handleCleanForm()
    setShowNovoIndicadorModal(false)
  }

  const handleEditTempIndicador = (formObject: IFormData): void => {
    if (indicadorModalData) {
      const normalizedTempIndicador = normalizeTempIndicadorObject(formObject)
      const newData = tempIndicadores.map((indicador) => {
        if (indicador.id === indicadorModalData.id) {
          return normalizedTempIndicador
        }

        return indicador
      })
      setTempIndicadores(newData)
      handleCloseNovoIndicadorModal()
      setIndicadorModalData(undefined)
    }
  }

  useEffect(() => {
    if (indicadorModalData) {
      setValue('atual', indicadorModalData.atual?.toString())
      setValue('linha_base', indicadorModalData.linha_base?.toString())
      setValue('meta', indicadorModalData.meta?.toString())
      setValue('nome', indicadorModalData.nome?.toString())
      setValue('periodicidade', {
        value: indicadorModalData.periodicidade,
        label: periodicidadeOptions[indicadorModalData.periodicidade].label
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicadorModalData])

  return (
    <ModalContainer isOpen={showNovoIndicadorModal} onClose={console.log}>
      <ModalOverlay />
      <ModalContent minWidth={800} className="nova-meta-modal-content">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          className="nova-meta-modal-header"
          color={'gray.900'}
          fontFamily={'Poppins'}
          fontSize={32}
          fontStyle={'normal'}
          fontWeight={700}
          lineHeight={'36px'}
        >
          Novo Indicador
        </ModalHeader>
        <ModalBody
          display={'flex'}
          flexDirection={'column'}
          padding={'0px 48px'}
          gap={'32px'}
          className="nova-meta-modal-body"
        >
          <div className="label-input-bundle">
            <label>Nome do indicador</label>
            <Controller name="nome" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
          <div className="label-input-bundle">
            <label>Meta</label>
            <Controller
              name="meta"
              control={control}
              render={({ field }) => <NumberMaskInput variant="flushed" {...field} />}
            />
          </div>
          <div className="label-input-bundle">
            <label>Atual</label>
            <Controller
              name="atual"
              control={control}
              render={({ field }) => <NumberMaskInput variant="flushed" {...field} />}
            />
          </div>
          <div className="label-input-bundle">
            <label>Linha de Base</label>
            <Controller
              name={`${!isNewProjectScreen && indicadorModalData ? 'linha_base' : 'atual'}`}
              control={control}
              render={({ field }) => <NumberMaskInput variant="flushed" isDisabled {...field} />}
            />
          </div>
          <div className="label-input-bundle">
            <label>Periodicidade</label>
            <Controller
              name="periodicidade"
              control={control}
              render={({ field }) => <GenericSelect options={periodicidadeOptions} {...field} />}
            />
          </div>
        </ModalBody>
        <ModalFooter display={'flex'} gap={'80px'}>
          {!isNewProjectScreen ? (
            <>
              {indicadorModalData ? (
                <GenericButton onClick={handleSubmit(handleClickSubmitEdit)} isDisabled={!isFormComplete}>
                  SALVAR EDIÇÃO
                </GenericButton>
              ) : (
                <GenericButton onClick={handleSubmit(handleClickSubmitCreate)} isDisabled={!isFormComplete}>
                  ADICIONAR E SALVAR
                </GenericButton>
              )}
            </>
          ) : indicadorModalData ? (
            <GenericButton onClick={handleSubmit(handleEditTempIndicador)} isDisabled={!isFormComplete}>
              SALVAR EDIÇÃO
            </GenericButton>
          ) : (
            <GenericButton onClick={handleSubmit(handleNovoTempIndicador)} isDisabled={!isFormComplete}>
              ADICIONAR
            </GenericButton>
          )}

          <GenericButton variant={'outline'} onClick={handleCloseNovoIndicadorModal} color={'#242731'}>
            CANCELAR
          </GenericButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
