import styled from 'styled-components'

export const KanbanBoardContainer = styled.div`
  padding: 44px 32px 32px 32px;
  .dashboard-title {
    font-size: 28px;
    color: var(--chakra-colors-gray-1000);
    margin-bottom: 18px;
  }
`

export const KanbanCalendarContainer = styled.div`
  padding: 44px 32px 32px 32px;
  .dashboard-title {
    font-size: 28px;
    color: var(--chakra-colors-gray-1000);
    margin-bottom: 18px;
  }
`

export const FirstRowContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 44px;
  justify-content: space-between;

  &.end {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .first-row-to-calendar {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
`

export const ContentContainer = styled.div`
  display: flex;
  gap: 30px;
`
