import { ODS, api } from 'sigap/utils/constants'

export async function listOds(): Promise<IOds[]> {
  const { data: response } = await api.get(`/${ODS}`)

  return response
}

export async function getOds(odsId: string): Promise<IOds> {
  const { data: response } = await api.get(`/${ODS}/${odsId}`)

  return response
}

export async function insertOds(ods: IOds): Promise<IOds> {
  const { data: response } = await api.post(`/${ODS}/`, ods)

  return response
}

export async function editOds(ods: IOds, odsId: number): Promise<IOds> {
  const { data: response } = await api.patch(`/${ODS}/${odsId}`, ods)

  return response
}
