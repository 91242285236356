import React from 'react'
import { FaFileContract } from 'react-icons/fa'
import { GiProgression } from 'react-icons/gi'
import { VscProject } from 'react-icons/vsc'

import { CardBody } from '@chakra-ui/react'
import * as dateFns from 'date-fns'

import { ITarefa } from 'sigap/types/tarefas'
import { DateFormat } from 'sigap/utils/constants/enums'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { returnStringFromBasicInfoArray } from 'sigap/utils/methods/returnStringFromBasicInfoArray'

import { TaskCardContainer } from './styles'

interface IProps {
  tarefa: ITarefa
}

const getIcon = (projeto, contrato, demanda) => {
  if (projeto) {
    return <VscProject style={{ marginLeft: 'auto', color: '#8C8C8C' }} />
  } else if (contrato) {
    return <FaFileContract style={{ marginLeft: 'auto', color: '#8C8C8C' }} />
  } else if (demanda) {
    return <GiProgression style={{ marginLeft: 'auto', color: '#8C8C8C' }} />
  } else {
    return null
  }
}

export const TaskCard: React.FC<IProps> = ({ tarefa }) => {
  return (
    <TaskCardContainer>
      <CardBody>
        {getIcon(tarefa.id_projeto, tarefa.id_contrato, tarefa.id_demanda)}
        <div>Título: {tarefa.titulo}</div>
        <div>Responsável: {returnStringFromBasicInfoArray(tarefa.responsavel as IBasicInfo[])}</div>
        <div>Data de finalização: {dateFns.format(new Date(addThreeDigits(tarefa.data_acao)), DateFormat.BR_DATE)}</div>
      </CardBody>
    </TaskCardContainer>
  )
}
