/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'

import { Input, InputProps } from '@chakra-ui/react'
import { NumberFormatProps, useNumberFormat } from '@react-input/number-format'

import { mergeRefs } from 'sigap/utils/methods/mergeRefs'

type Props = NumberFormatProps & InputProps

export const NumberMaskInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      currency,
      currencyDisplay,
      format,
      groupDisplay,
      locales,
      maximumFractionDigits,
      maximumIntegerDigits,
      minimumFractionDigits,
      minimumIntegerDigits,
      onNumberFormat,
      signDisplay,
      unit,
      unitDisplay,
      ...props
    },
    forwardRef
  ) => {
    const inputRef = useNumberFormat({
      currency,
      currencyDisplay,
      format,
      groupDisplay,
      locales,
      maximumFractionDigits,
      maximumIntegerDigits,
      minimumFractionDigits,
      minimumIntegerDigits,
      onNumberFormat,
      signDisplay,
      unit,
      unitDisplay
    })

    return <Input {...props} ref={mergeRefs([inputRef, forwardRef])} />
  }
)
