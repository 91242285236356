import React, { useCallback, useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { useRequest } from 'ahooks'
import { ta } from 'date-fns/locale'
import moment from 'moment'

import { listTarefas } from 'sigap/services/tarefas'
import { ITarefa } from 'sigap/types/tarefas'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'

export type IFormattedDate = {
  dayOfTheMonth: number
  dayOfTheWeek: number
}

const localizer = momentLocalizer(moment)

const events = [
  {
    title: 'teste tarefafffff',
    responsavel: [{ nome: 'paulo', id: 2 }],
    data_acao: 1703224800,
    status_id: 2,
    id_tarefa: 27,
    created_at: '2023-12-14T02:25:56.102079',
    updated_at: '2023-12-18T21:41:22.074050',
    id_projeto: 67,
    id_demanda: null,
    id_contrato: null,
    tipo: 'projeto',
    start: new Date('2023-12-14T02:25:56.102079'),
    end: new Date('December 17, 2024 03:24:00')
  }
]

interface IFormattedTarefa extends ITarefa {
  start: Date
  end: Date
}

interface IProps {
  tarefas: ITarefa[]
  setShowNovaTarefaModal: React.Dispatch<React.SetStateAction<boolean>>
  setTarefaModalData: React.Dispatch<React.SetStateAction<ITarefa>>
}

export const KanbanCalendar: React.FC<IProps> = ({ tarefas, setShowNovaTarefaModal, setTarefaModalData }) => {
  const [formattedTarefas, setFormattedTarefas] = useState<IFormattedTarefa[]>([])

  const handleSelectEvent = useCallback((event) => {
    setTarefaModalData(event)
    setShowNovaTarefaModal(true)
  }, [])

  useEffect(() => {
    if (tarefas) {
      const formattedTarefas = tarefas.map((tarefa) => {
        return {
          ...tarefa,
          start: new Date(tarefa.created_at),
          end: new Date(addThreeDigits(tarefa.data_acao)),
          title: tarefa.titulo
        }
      })

      setFormattedTarefas(formattedTarefas)
    }
  }, [tarefas])

  const messages = {
    allDay: 'Dia Inteiro',
    previous: '<',
    next: '>',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento'
  }

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={formattedTarefas}
        startAccessor="start"
        endAccessor="end"
        messages={messages}
        style={{ height: 1000 }}
        onSelectEvent={handleSelectEvent}
      />
    </div>
  )
}
