import React from 'react'
import { useParams } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { getProject } from 'sigap/services/projects'
import { LoadingScreen } from 'sigap/utils/generic/LoadingScreen/LoadingScreen'
import { NotFoundScreen } from 'sigap/utils/generic/NotFoundScreen/NotFoundScreen'

import { Project } from './FullyApprovedProject/Project'

export const ProjectEditor: React.FC = () => {
  const params = useParams()
  const {
    loading: projectLoading,
    data: project,
    refreshAsync: refreshProject
  } = useRequest(getProject, { defaultParams: [Number(params.id)], ready: !!params.id })

  if (projectLoading && !project) {
    return <LoadingScreen />
  }
  if (!projectLoading && !project && !isNaN(Number(params.id))) {
    return <NotFoundScreen type="Projeto" />
  }

  return <Project project={project} refreshProject={refreshProject} />
}
