import { Card } from '@chakra-ui/react'
import styled from 'styled-components'

export const LargeCardInfo = styled(Card)`
  border-radius: 10px;
  .large-card-info-body {
    display: flex;
    justify-content: space-between;
    padding: 24px;

    .large-card-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;

      .label-info-bundle {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .project-info {
        color: var(--chakra-colors-gray-1000);
      }
    }
  }

  .creator-list-footer {
    padding: 16px;
  }
`
