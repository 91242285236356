import React from 'react'

import { NotFoundScreenContainer } from './styles'

interface IProps {
  type: string
  f?: boolean
}

export const NotFoundScreen: React.FC<IProps> = ({ type, f }) => {
  if (f) {
    return (
      <NotFoundScreenContainer>
        <div>{`${type} não encontrada.`}</div>
      </NotFoundScreenContainer>
    )
  }

  return (
    <NotFoundScreenContainer>
      <div>{`${type} não encontrado.`}</div>
    </NotFoundScreenContainer>
  )
}
