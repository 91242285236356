import { TableColumn } from 'react-data-table-component'

export const documentoColumns: TableColumn<IDocumentos>[] = [
  // {
  //   name: 'Nome',
  //   selector: (row) => row.nome,
  //   sortable: true
  // },
  {
    name: 'Link',
    selector: row => row.nome,
    cell: (row) => (
      <a style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} href={row.link} target="_blank" rel="noreferrer"> {row.nome} </a>
    ),
    sortable: true
  }
]
