import React from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { FaSearch } from 'react-icons/fa'

import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import { SerchBarContainer } from './styles'

interface IProps {
  inputWidth?: number | string
  inputHeight?: number | string
  setSearchTerm: (term: string) => void
  placeholder?: string
}

export const SearchBar: React.FC<IProps> = ({ inputWidth, inputHeight, setSearchTerm, placeholder }) => {
  const handleSearchTerm = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value)
  }

  return (
    <SerchBarContainer>
      <InputGroup>
        <InputLeftAddon>
          <FaSearch />
        </InputLeftAddon>
        <Input
          placeholder={placeholder ?? 'Pesquisar'}
          width={inputWidth ?? '100%'}
          height={inputHeight ?? ''}
          className="search-input"
          onChange={(e) => handleSearchTerm(e)}
        />
      </InputGroup>
    </SerchBarContainer>
  )
}
