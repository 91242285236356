import styled from 'styled-components'

export const ViewContainer = styled.div`
  width: 100%;
  overflow: scroll;
  height: 100vh;

  .view-top-bar {
    width: 100%;
    background-color: var(--chakra-colors-gray-200);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    padding: 10px 30px 10px 0;
    font-size: 20px;
  }
`
