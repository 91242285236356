import styled from 'styled-components'

export const PendingApprovalScreenContainer = styled.div`
  /* width: 100%; */
  .return-projects-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 0;
  }

  .pending-approval-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    flex-direction: column;
    gap: 4px;

    .body-additional {
      font-size: 32px;
      line-height: 40px;
    }
  }
`
