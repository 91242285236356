import styled from 'styled-components'

export const DashboardContainer = styled.div`
  padding: 67px 50px 65px 77px;
  .dashboard-core {
    .core-nav-tabs {
      display: flex;
      margin-left: 10px;

      .core-nav-tab {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        border: 2px solid black;
        border-radius: 10px;
        padding: 14px 55px 14px 14px;
      }
    }

    .core-body {
      padding: 65px 20px 20px 20px;
      border: 2px solid black;
      border-radius: 10px;
      transform: translateY(-6px);
      width: 100%;
    }
  }
`
