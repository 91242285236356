import styled from 'styled-components'

export const PerfilContainer = styled.div`
  padding: 32px;
  .perfil-header {
    color: #242731;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    // padding-left: 0px;
    padding-top: 26px;
    display: flex;
    // justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .perfil-body {
    display: grid;
    grid-template-columns: 0.75fr 0.25fr;
    padding-top: 32px;
    .perfil-body-form-column {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .label-input-bundle {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    .perfil-body-picture {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .picture-frame {
        border: 2px solid black;
        height: 100px;
        width: 100px;
        border-radius: 100px;
      }
      .pic-input {
        position: absolute;
        bottom: 36px;
        right: 148px;
        z-index: 1;
      }
    }
  }
`
