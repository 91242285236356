import { SEDEICS, api } from 'sigap/utils/constants'

export async function listSedeics(): Promise<ISedeics[]> {
  const { data: response } = await api.get(`/${SEDEICS}`)

  return response
}

export async function getSedeics(sedeicsId: string): Promise<ISedeics> {
  const { data: response } = await api.get(`/${SEDEICS}/${sedeicsId}`)

  return response
}

export async function insertSedeics(sedeics: ISedeics): Promise<ISedeics> {
  const { data: response } = await api.post(`/${SEDEICS}/`, sedeics)

  return response
}

export async function editSedeics(sedeics: ISedeics, sedeicsID: number): Promise<ISedeics> {
  const { data: response } = await api.patch(`/${SEDEICS}/${sedeicsID}`, sedeics)

  return response
}
