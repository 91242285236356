import React from 'react'

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import { ODSList } from './ODSList/ODSList'
import { SedeicsList } from './SedeicsList/SedeicsList'
import { AdminContainer } from './styles'
import { SubsecretariaList } from './SubsecretariaList/SubsecretariaList'
import { UserList } from './UserList/UserList'

export const Admin: React.FC = () => {
  return (
    <AdminContainer>
      <Tabs>
        <TabList className="tab-list-admin">
          <Tab sx={{ _selected: { textDecoration: 'underline', color: 'white', fontWeight: 'bold' } }}>Usuários</Tab>
          <Tab sx={{ _selected: { textDecoration: 'underline', color: 'white', fontWeight: 'bold' } }}>
            Subsecretarias
          </Tab>
          <Tab sx={{ _selected: { textDecoration: 'underline', color: 'white', fontWeight: 'bold' } }}>ODS</Tab>
          <Tab sx={{ _selected: { textDecoration: 'underline', color: 'white', fontWeight: 'bold' } }}>
            Missões SEDEICS
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <UserList />
          </TabPanel>
          <TabPanel>
            <SubsecretariaList />
          </TabPanel>
          <TabPanel>
            <ODSList />
          </TabPanel>
          <TabPanel>
            <SedeicsList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </AdminContainer>
  )
}
