import React, { useState } from 'react'
import { BsPlusLg } from 'react-icons/bs'
import { useParams } from 'react-router-dom'

import { CircularProgress } from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { getTarefasByContrato, getTarefasByDemanda, getTarefasByProject, IEtapasDto } from 'sigap/services/tarefas'
import { listUsers } from 'sigap/services/users'
import { IEtapa, ITarefa } from 'sigap/types/tarefas'
import { EndButtonRow } from 'sigap/utils/generic/EndButtonRow/styles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'

import { ModalNovaEtapa } from './ModalNovaEtapa/ModalNovaEtapa'
import { EtapaListContainer } from './styles'
import { TarefaList } from './TarefaList/TarefaList'

interface IProps {
  isPending?: boolean
  entityId?: number
  isNewEntityScreen: boolean
  type: 'PROJETO' | 'CONTRATO' | 'DEMANDA'
  tempEtapas: IEtapa[]
  setTempEtapas: React.Dispatch<React.SetStateAction<IEtapa[]>>
}

export const EtapaList: React.FC<IProps> = ({
  isPending,
  isNewEntityScreen,
  entityId,
  type,
  setTempEtapas,
  tempEtapas
}) => {
  const params = useParams()

  const fetchDataMethod = () => {
    if (type === 'DEMANDA') {
      return getTarefasByDemanda
    }
    if (type === 'PROJETO') {
      return getTarefasByProject
    }
    if (type === 'CONTRATO') {
      return getTarefasByContrato
    }
  }
  const {
    data: etapas,
    refreshAsync: refreshEtapas,
    loading: etapasLoading
  } = useRequest((id) => fetchDataMethod()(id), {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })

  const { data: users } = useRequest(listUsers)

  const [showNovaEtapaModal, setShowNovaEtapaModal] = useState<boolean>()
  const [etapaModalData, setEtapaModalData] = useState<IEtapa>()

  const [showNovaTarefaModal, setShowNovaTarefaModal] = useState<boolean[]>(new Array(etapas?.length).fill(false))

  const handleShowNovaEtapaModal = (): void => {
    setShowNovaEtapaModal(true)
  }

  return (
    <>
      <EtapaListContainer>
        <div className="etapa-header">ETAPAS</div>

        <div className="etapa-content">
          {etapasLoading ? (
            <CircularProgress isIndeterminate color="#001B37" />
          ) : (
            <>
              <div className="tarefa-list">
                {!etapas && tempEtapas?.length === 0 && <NoDataComponent dataType="etapas" />}
                {tempEtapas.length > 0
                  ? tempEtapas?.map((tempEtapa, index) => (
                      <TarefaList
                        refreshEtapas={refreshEtapas}
                        key={tempEtapa.id_etapa}
                        tarefas={tempEtapa.tarefas}
                        isPending={isPending}
                        tarefasLoading={etapasLoading}
                        setTempEtapas={setTempEtapas}
                        tempEtapas={tempEtapas}
                        etapa={tempEtapa}
                        showNovaTarefaModal={showNovaTarefaModal}
                        isNewEntityScreen={isNewEntityScreen}
                        index={index}
                        length={etapas?.length}
                        users={users}
                      />
                    ))
                  : etapas?.map((etapa, index) => (
                      <TarefaList
                        refreshEtapas={refreshEtapas}
                        key={etapa.id_etapa}
                        tarefas={etapa.tarefas}
                        isPending={isPending}
                        tarefasLoading={etapasLoading}
                        tempEtapas={tempEtapas}
                        etapa={etapa}
                        showNovaTarefaModal={showNovaTarefaModal}
                        isNewEntityScreen={isNewEntityScreen}
                        setTempEtapas={setTempEtapas}
                        index={index}
                        length={etapas?.length}
                        users={users}
                      />
                    ))}
              </div>
              <EndButtonRow style={{ padding: '16px' }}>
                <GenericButton
                  className="nova-tarefa-button"
                  colorScheme="green"
                  onClick={handleShowNovaEtapaModal}
                  isDisabled={isPending}
                  rightIcon={<BsPlusLg />}
                >
                  Adicionar Etapa
                </GenericButton>
              </EndButtonRow>
            </>
          )}
        </div>
      </EtapaListContainer>
      <ModalNovaEtapa
        entityId={entityId}
        setShowNovaEtapaModal={setShowNovaEtapaModal}
        tempEtapas={tempEtapas}
        refreshEtapas={refreshEtapas}
        setEtapaModalData={setEtapaModalData}
        showNovaEtapaModal={showNovaEtapaModal}
        type={type}
        isNewEntityScreen={isNewEntityScreen}
        setTempEtapas={setTempEtapas}
      />
    </>
  )
}
