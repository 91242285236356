import React, { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { FaRegFloppyDisk } from 'react-icons/fa6'

import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  useToast
} from '@chakra-ui/react'

import { IAprovacaoObj, insertAprovacao } from 'sigap/services/projects'
import { IProject } from 'sigap/types/projects'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'

interface IFormData {
  comentario: string
}

interface IProps {
  projectId: number
  showAprovacaoModal: boolean
  setShowAprovacaoModal: React.Dispatch<React.SetStateAction<boolean>>
  refreshProject: () => Promise<IProject>
}

const formDefault: IFormData = {
  comentario: ''
}

export const ModalAprovacao: React.FC<IProps> = ({
  projectId,
  showAprovacaoModal,
  setShowAprovacaoModal,
  refreshProject
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<IFormData>({ defaultValues: formDefault })
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [radioValue, setRadioValue] = React.useState('1')
  const [count, setCount] = useState<number>(1)

  const currentFormData = watch()

  const toast = useToast()

  const isFormComplete = useMemo(() => currentFormData.comentario?.length > 0, [currentFormData])

  const handleClose = (): void => {
    setShowAprovacaoModal(false)
    setValue('comentario', '')
    setRadioValue('1')
    refreshProject()
  }

  const formatAprovacaoObj = (formObject: IFormData): IAprovacaoObj => {
    const aprovacaoObj: IAprovacaoObj = {
      aprovado: radioValue === '1',
      comentario: formObject.comentario
    }

    return aprovacaoObj
  }

  const handleAprovacao = async (formObject: IFormData): Promise<void> => {
    const aprovacaoObj = formatAprovacaoObj(formObject)
    try {
      await insertAprovacao(projectId, aprovacaoObj)
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ModalContainer isOpen={showAprovacaoModal} onClose={console.log}>
      <ModalOverlay />
      <ModalContent minWidth={800} className="nova-meta-modal-content">
        <ModalHeader></ModalHeader>
        <ModalBody
          display={'flex'}
          flexDirection={'column'}
          padding={'0px 48px'}
          gap={'32px'}
          className="nova-meta-modal-body"
        >
          <RadioGroup onChange={setRadioValue} value={radioValue}>
            <Stack direction="row" justifyContent={'center'}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                  <FaCheckCircle size={'61px'} color={'green'} />
                </div>
                <Radio value="1" borderColor={'black'}>
                  Aprovar
                </Radio>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                  <FaTimesCircle size={'61px'} color={'red'} />
                </div>
                <Radio value="2" borderColor={'black'}>
                  Reprovar
                </Radio>
              </div>
            </Stack>
          </RadioGroup>
          <div>
            <div style={{ color: radioValue === '2' && !isFormComplete && 'red' }}>Comentário</div>
            <Controller
              name="comentario"
              control={control}
              rules={{
                validate: (value) => {
                  if (radioValue === '2' && !value) {
                    return 'Comentário necessário'
                  }

                  return true
                }
              }}
              render={({ field }) => (
                <Textarea borderColor={radioValue === '2' && !isFormComplete && 'red'} {...field} />
              )}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <GenericButton colorScheme={'blue'} display={'flex'} gap={'8px'} onClick={handleSubmit(handleAprovacao)}>
            Enviar <FaRegFloppyDisk />
          </GenericButton>
          &ensp;
          <GenericButton colorScheme={'gray'} onClick={handleClose}>
            Fechar
          </GenericButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
