import { DEMANDAS, api } from 'sigap/utils/constants'

export async function getDemanda(demandaId: number): Promise<IDemanda> {
  const { data: response } = await api.get(`/${DEMANDAS}/${demandaId}`)

  return response
}

export async function listDemandas(): Promise<IDemanda[]> {
  const { data: response } = await api.get(`/${DEMANDAS}`)

  return response
}
export async function insertDemanda(demanda: IDemanda): Promise<{ id_demanda: number }> {
  const { data: response } = await api.post(`/${DEMANDAS}`, demanda)

  return response
}
export async function editDemanda(demanda: IDemanda, demandaId: number): Promise<{ id_demanda: number }> {
  const { data: response } = await api.patch(`/${DEMANDAS}/${demandaId}`, demanda)

  return response
}

export async function insertCommentOnDemanda(comment: IComment, demandaId: number): Promise<void> {
  const { data: response } = await api.post(`/${DEMANDAS}/${demandaId}/comentario`, comment)

  return response
}

export async function getCommentsOnDemanda(demandaId: number): Promise<IComment[]> {
  const { data: response } = await api.get(`/${DEMANDAS}/${demandaId}/comentario`)

  return response
}
