import React, { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button, FormControl, Input, InputGroup, InputRightElement, useToast } from '@chakra-ui/react'

import { IAuthNewUser, IResetPassword } from 'sigap/services/users'
import { PASSWORD_RESET_TOAST_MESSAGE, USER_CREATION_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'

interface IFormData {
  password: string
  passwordVerify: string
}

interface IProps {
  token: string
  handleResetPassword: (resetPass: IResetPassword, token: string) => Promise<void>
}

export const ResetPassword: React.FC<IProps> = ({ token, handleResetPassword }) => {
  const [show, setShow] = useState<boolean>(false)
  const { handleSubmit, control, watch } = useForm<IFormData>({
    mode: 'all'
  })

  const currentFormData = watch()

  const handleShowPassword = (): void => setShow(!show)
  const passwordsMatch = useMemo(() => {
    if (currentFormData?.password?.length > 0 && currentFormData?.passwordVerify?.length > 0) {
      return currentFormData?.password === currentFormData?.passwordVerify
    }
  }, [currentFormData])

  const toast = useToast()

  const handleClickSubmit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleResetPassword(formObject, token), PASSWORD_RESET_TOAST_MESSAGE)
  }

  return (
    <>
      <div className="auth-header">Redefinir senha</div>
      <FormControl className="form-control-container">
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <InputGroup size="md" {...field}>
              <Input variant="flushed" type={show ? 'text' : 'password'} placeholder="Senha" />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowPassword}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          )}
        />
        <Controller
          name="passwordVerify"
          control={control}
          render={({ field }) => (
            <InputGroup size="md" {...field}>
              <Input variant="flushed" type={show ? 'text' : 'password'} placeholder="Repita sua senha" />
            </InputGroup>
          )}
        />
      </FormControl>
      <Button width={'100%'} colorScheme="green" onClick={handleSubmit(handleClickSubmit)} isDisabled={!passwordsMatch}>
        Redefinir senha
      </Button>
    </>
  )
}
