export const addThreeDigits = (time: number): number => {
  if (time) {
    const timeString = time.toString()
    if (timeString.length === 10) {
      const modifiedTimeString = timeString + '000'

      return Number(modifiedTimeString)
    }

    return time
  }

  return new Date().getTime()
}
