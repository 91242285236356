import React from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import { CircularProgress } from '@chakra-ui/react'
import { useRequest } from 'ahooks'
import * as dateFns from 'date-fns'

import { listContratos } from 'sigap/services/contracts'
import { IContrato } from 'sigap/types/contracts'
import { DateFormat } from 'sigap/utils/constants/enums'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { LoadingScreen } from 'sigap/utils/generic/LoadingScreen/LoadingScreen'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { returnStringFromBasicInfoArray } from 'sigap/utils/methods/returnStringFromBasicInfoArray'

import { ContractsContainer } from './styles'

const columns: TableColumn<IContrato>[] = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    grow: 10
  },
  {
    name: 'CONTRATO',
    selector: (row) => row.nome,
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'SUBSECRETARIA',
    selector: (row) => (row.subsecretaria ? (row.subsecretaria as IBasicInfo).nome : '-'),
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'GESTORES',
    selector: (row) =>
      row.gestores && row.gestores.length > 0 ? returnStringFromBasicInfoArray(row.gestores as IBasicInfo[]) : '-',
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'CELEBRADO',
    selector: (row) => (row.celebrado ? (row.celebrado as IBasicInfo).nome : '-'),
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'VIGÊNCIA',
    selector: (row) =>
      `${dateFns.format(addThreeDigits(row.duracao?.inicio), DateFormat.BR_DATE)} - ${dateFns.format(
        addThreeDigits(row.duracao?.fim),
        DateFormat.BR_DATE
      )}`,
    sortable: true,
    grow: 40,
    wrap: true
  }
]

export const Contracts: React.FC = () => {
  const navigate = useNavigate()

  const { data: contratos, loading: contratosLoading } = useRequest(listContratos)

  if (contratosLoading) {
    return <LoadingScreen />
  }

  return (
    <ContractsContainer>
      <div className="button-row-container">
        <GenericButton onClick={() => navigate('/contratos/novo')} colorScheme="green">
          Novo Contrato
          <BsPlusLg />
        </GenericButton>
      </div>
      <div className="contratos-body">
        <div className="table-title contratos">
          <div className="table-title-header">Contratos</div>
          <div className="table-container">
            {contratosLoading ? (
              <div className="loading-container">
                <CircularProgress isIndeterminate color="#001B37" />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={contratos}
                dense
                onRowClicked={(contrato) => navigate(`/contratos/${contrato.id}`)}
                pointerOnHover
                highlightOnHover
                striped
                noDataComponent={<NoDataComponent dataType="contratos" />}
              />
            )}
          </div>
        </div>
      </div>
    </ContractsContainer>
  )
}
