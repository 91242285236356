import React, { useState, useContext } from 'react'
import { MdOutlineCloudDownload } from 'react-icons/md'
import { RiMenuFoldFill, RiMenuUnfoldFill } from 'react-icons/ri'
import { useNavigate, useLocation } from 'react-router-dom'

import cx from 'classnames'

import { AuthenticationContext } from 'sigap/context/AuthenticationContext'
import { sideBarSecretariaLogo, sideBarSigestLogo } from 'sigap/resources/images'
import { routes } from 'sigap/utils/constants'

import { HiddenSideBar, SideBarContainer } from './styles'

export const SideBar: React.FC = () => {
  const [highlight, setHighlight] = useState<string>('Dashboard')
  const [hideSideBar, setHideSideBar] = useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation()
  const authContext = useContext(AuthenticationContext)

  const handleHideSideBar = (): void => {
    setHideSideBar(true)
  }

  const handleShowSideBar = (): void => {
    setHideSideBar(false)
  }

  if (hideSideBar) {
    return (
      <HiddenSideBar>
        <div className="hidden-bar-column">
          <div className="hidden-bar-button">
            <RiMenuUnfoldFill size={'2em'} onClick={handleShowSideBar} />
          </div>
          {routes.map(({ icon: Icon, ...route }, index) => (
            <div
              className={cx(`hidden-bar-button`, { selected: route.path === location.pathname })}
              key={index}
              onClick={() => {
                navigate(route.path)
                setHighlight(route.name)
              }}
            >
              <Icon size={'2em'} />
            </div>
          ))}
        </div>
      </HiddenSideBar>
    )
  }

  return (
    <SideBarContainer className="side-bar noprint">
      <div className="collapse-icon-row">
        <RiMenuFoldFill size={'2em'} onClick={handleHideSideBar} />
      </div>

      <div className="side-bar-sigest-logo-container">
        <img src={sideBarSigestLogo} alt="Logo Sigest barra lateral" />
      </div>
      <div className="side-bar-button-container">
        {routes.map(({ icon: Icon, ...route }, index) => (
          <div
            className={cx(`side-bar-button`, { selected: route.path === location.pathname })}
            key={index}
            onClick={() => {
              navigate(route.path)
              setHighlight(route.name)
            }}
          >
            <Icon size={20} />
            {route.name}
          </div>
        ))}
      </div>

      <div className="side-bar-secretaria-logo-container">
        {' '}
        <img src={sideBarSecretariaLogo} alt="Logo Secretaria barra lateral" />
      </div>
    </SideBarContainer>
  )
}
