import { PUBLICO_ALVO, api } from 'sigap/utils/constants'

export async function listPublicoAlvo(): Promise<IPublicoAlvo[]> {
  const { data: response } = await api.get(`/${PUBLICO_ALVO}`)

  return response
}

export async function getPublicoAlvo(publicoAlvoId: string): Promise<IPublicoAlvo> {
  const { data: response } = await api.get(`/${PUBLICO_ALVO}/${publicoAlvoId}`)

  return response
}

export async function insertPublicoAlvo(publicoAlvo: IPublicoAlvo): Promise<IPublicoAlvo> {
  const { data: response } = await api.post(`/${PUBLICO_ALVO}`, publicoAlvo)

  return response
}
