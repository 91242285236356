import React, { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button, FormControl, Input, InputGroup, InputRightElement, useToast } from '@chakra-ui/react'

import { IAuthUser } from 'sigap/services/users'
import { LOGIN_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

interface IFormData {
  email: string
  password: string
}

interface IProps {
  handleLogin: (user: IAuthUser) => Promise<void>
  handleGoToForgotPassword: () => void
  handleGoToNewUser: () => void
}

export const DefaultLogin: React.FC<IProps> = ({ handleLogin, handleGoToForgotPassword, handleGoToNewUser }) => {
  const [show, setShow] = useState<boolean>(false)

  const { handleSubmit, control, watch } = useForm<IFormData>({
    mode: 'all'
  })

  const toast = useToast()

  const SwalAlert = withReactContent(Swal)

  const currentFormData = watch()

  const isFormComplete = useMemo(
    () => currentFormData.email?.length > 0 && currentFormData.password?.length > 0,
    [currentFormData]
  )

  const handleShowPassword = (): void => setShow(!show)

  const handleClickSubmit = async (formObject: IFormData): Promise<void> => {
    try {
      // Display the loading state
      SwalAlert.fire({
        toast: true,
        position: 'bottom',
        title: 'Entrando...',
        text: 'Por favor, aguarde.',
        showConfirmButton: false,
        didOpen: () => {
          SwalAlert.showLoading();
        },
        allowOutsideClick: false, // Prevents closing the popup by clicking outside
      });
  
      // Call the login function (replace with actual logic)
      await handleLogin(formObject);
  
      // If successful, show success message
      SwalAlert.fire({
        toast: true,
        position: 'bottom',
        icon: 'success',
        title: 'Sucesso!',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        // text: 'You have successfully logged in.',
      });
    } catch (error) {
      // If there's an error, show an error message
      SwalAlert.fire({
        toast: true,
        position: 'bottom',
        icon: 'error',
        title: 'Usuário ou senha inválidos',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        // text: 'Something went wrong during login. Please try again.',
      });
    }
  }

  return (
    <>
      <div className="auth-header">Login</div>
      <FormControl className="form-control-container">
        <Controller
          name="email"
          control={control}
          render={({ field }) => <Input variant="flushed" placeholder="E-mail" type="email" {...field} />}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <InputGroup size="md" {...field}>
              <Input
                variant="flushed"
                type={show ? 'text' : 'password'}
                placeholder="Senha"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(handleClickSubmit)()
                  }
                }}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowPassword}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          )}
        />
      </FormControl>
      <Button width={'100%'} colorScheme="green" onClick={handleSubmit(handleClickSubmit)} isDisabled={!isFormComplete}>
        Acessar
      </Button>
      <div className="bottom-link-container">
        <div className="forgot-password" onClick={handleGoToForgotPassword}>
          Esqueceu sua senha?
        </div>
        <div className="new-user" onClick={handleGoToNewUser}>
          Novo usuário
        </div>
      </div>
    </>
  )
}