import styled from 'styled-components'

export const AdminContainer = styled.div`
  .button-row-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 32px;
    > button {
      display: flex;
      gap: 10px;
    }
  }
  .admin-body {
    .table-title {
      padding: 0 100px 100px 100px;
      display: flex;
      flex-direction: column;
      gap: 38px;
      .table-title-header {
        font-size: 32px;
        font-weight: 700;
        color: var(--chakra-colors-gray-1000);
      }
      .table-container {
        .loading-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .tab-list-admin {
    background-color: var(--chakra-colors-blue-600);
    color: white;
  }
`
