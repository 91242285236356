import React from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import { Button, CircularProgress } from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { listDemandas } from 'sigap/services/demandas'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { LoadingScreen } from 'sigap/utils/generic/LoadingScreen/LoadingScreen'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'
import { returnStringFromBasicInfoArray } from 'sigap/utils/methods/returnStringFromBasicInfoArray'

import { DemandsContainer } from './styles'

const columns: TableColumn<IDemanda>[] = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true
  },
  {
    name: 'DEMANDA',
    selector: (row) => row.nome,
    sortable: true
  },
  {
    name: 'SUBSECRETARIA',
    selector: (row) => (row.subsecretaria ? (row.subsecretaria as IBasicInfo).nome : '-'),
    sortable: true
  },
  {
    name: 'DEMANDANTE',
    selector: (row) => (row.demandante ? (row.demandante as IBasicInfo).nome : '-'),
    sortable: true
  },
  {
    name: 'EQUIPE',
    selector: (row) =>
      row.equipe && row.equipe.length > 0 ? returnStringFromBasicInfoArray(row.equipe as IBasicInfo[]) : '-',
    sortable: true
  },
  {
    name: 'CLASSIFICAÇÃO',
    selector: (row) =>
      row.classificacao && row.classificacao.length > 0
        ? returnStringFromBasicInfoArray(row.classificacao as IBasicInfo[])
        : '-',
    sortable: true
  },
  {
    name: '% TAREFAS CONCLUIDAS',
    selector: (row) => row.status,
    sortable: true
  },
  {
    name: 'STATUS',
    selector: (row) => row.status,
    sortable: true
  }
]

export const Demands: React.FC = () => {
  const navigate = useNavigate()

  const { data: demands, loading: demandsLoading } = useRequest(listDemandas)

  if (demandsLoading) {
    return <LoadingScreen />
  }

  return (
    <DemandsContainer>
      <div className="button-row-container">
        <GenericButton onClick={() => navigate('/demandas/novo')} colorScheme="green">
          Nova Demanda
          <BsPlusLg />
        </GenericButton>
      </div>
      <div className="demands-body">
        <div className="table-title demandas">
          <div className="table-title-header">Demandas</div>
          <div className="table-container">
            {demandsLoading ? (
              <div className="loading-container">
                <CircularProgress isIndeterminate color="#001B37" />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={demands}
                dense
                onRowClicked={(demanda) => navigate(`/demandas/${demanda.id}`)}
                pointerOnHover
                highlightOnHover
                striped
                noDataComponent={<NoDataComponent dataType="demandas" />}
              />
            )}
          </div>
        </div>
        <div></div>
      </div>
    </DemandsContainer>
  )
}
