import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { MdDeleteOutline } from 'react-icons/md'

import { CircularProgress } from '@chakra-ui/react'
import { ProgressBar } from '@primer/react'
import * as dateFns from 'date-fns'

import { deleteTarefa } from 'sigap/services/tarefas'
import { IEtapa, ITarefa } from 'sigap/types/tarefas'
import { DateFormat } from 'sigap/utils/constants/enums'
import { EndButtonRow } from 'sigap/utils/generic/EndButtonRow/styles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { returnStringFromBasicInfoArray } from 'sigap/utils/methods/returnStringFromBasicInfoArray'

import { IndicadoresTarefasContainer } from '../../IndicadoresList/styles'
import { ModalNovaTarefa } from '../../ModalNovaTarefa/ModalNovaTarefa'

interface IProps {
  tempEtapas?: IEtapa[]
  tarefas: ITarefa[]
  isPending?: boolean
  tarefasLoading: boolean
  etapa: IEtapa
  refreshEtapas: () => Promise<IEtapa[]>
  isNewEntityScreen: boolean
  setTempEtapas: React.Dispatch<React.SetStateAction<IEtapa[]>>
  showNovaTarefaModal: boolean[]
  index: number
  length: number
  users: IUser[]
}

export const TarefaList: React.FC<IProps> = ({
  tarefas,
  tempEtapas,
  isPending,
  tarefasLoading,
  etapa,
  index,
  refreshEtapas,
  isNewEntityScreen,
  setTempEtapas,
  length,
  users
}) => {
  const [tarefaModalData, setTarefaModalData] = useState<ITarefa>()
  const [showNovaTarefaModal, setShowNovaTarefaModal] = useState<boolean[]>(new Array(length).fill(false))

  const handleDeleteTarefa = async (row): Promise<void> => {
    await deleteTarefa(row.id_tarefa)
    refreshEtapas()
  }

  const columns: TableColumn<ITarefa>[] = [
    {
      name: 'Título',
      selector: (row) => row.titulo,
      sortable: true
    },
    {
      name: 'Responsável',
      selector: (row) =>
        row.responsavel && row.responsavel.length > 0
          ? returnStringFromBasicInfoArray(row.responsavel as IBasicInfo[])
          : '-',
      sortable: true
    },

    {
      name: 'Prazo',
      selector: (row) => dateFns.format(addThreeDigits(row.data_acao), DateFormat.BR_DATE),
      sortable: true
    },
    {
      name: 'Status',
      cell: (row) => (
        <ProgressBar aria-valuenow={100} aria-label="Upload test.png" width={'100px'}>
          <ProgressBar.Item
            progress={33}
            sx={{
              bg: '#dadada'
            }}
          />
          <ProgressBar.Item
            progress={38}
            sx={{
              bg: '#aeae'
            }}
          />
          <ProgressBar.Item
            progress={29}
            sx={{
              bg: '#fe6'
            }}
          />
        </ProgressBar>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '100px'
    },
    {
      name: 'Ações',
      cell: (row) => (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <FaTrash size={20} onClick={() => handleDeleteTarefa(row)} />
          {/* <FaEdit size={20} /> */}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ]
  const handleShowNovaTarefaModal = (): void => {
    const newModalState = showNovaTarefaModal.map((state, i) => i === index)
    setShowNovaTarefaModal(newModalState)
  }

  const closeModal = (): void => {
    const newModalState = showNovaTarefaModal.map((state, i) => (i === index ? false : state))
    setShowNovaTarefaModal(newModalState)
  }

  const handleOnRowClick = (tarefa: ITarefa): void => {
    handleShowNovaTarefaModal()
    setTarefaModalData(tarefa)
  }

  useEffect(() => {
    if (tempEtapas) {
      setShowNovaTarefaModal(new Array(tempEtapas.length).fill(false))
    }
  }, [tempEtapas])

  useEffect(() => {
    setShowNovaTarefaModal(new Array(length).fill(false))
  }, [length])

  return (
    <>
      <IndicadoresTarefasContainer>
        <div className="meta-header">{etapa.nome}</div>
        <div className="meta-content">
          <div style={{ padding: '0 32px' }}>{etapa.descricao}</div>
          <div className="tarefa-list">
            <DataTable
              columns={columns}
              data={etapa?.tarefas}
              onRowClicked={(tarefa) => handleOnRowClick(tarefa)}
              dense
              pointerOnHover={!isPending}
              highlightOnHover={!isPending}
              striped
              progressPending={tarefasLoading}
              progressComponent={<CircularProgress isIndeterminate color="#001B37" />}
              noDataComponent={<NoDataComponent dataType="tarefas" />}
            />
          </div>
          <EndButtonRow style={{ padding: '16px' }}>
            <GenericButton
              className="nova-tarefa-button"
              colorScheme="green"
              onClick={handleShowNovaTarefaModal}
              isDisabled={isPending}
              rightIcon={<BsPlusLg />}
            >
              Adicionar Tarefa
            </GenericButton>
          </EndButtonRow>
        </div>
      </IndicadoresTarefasContainer>
      <ModalNovaTarefa
        etapaId={etapa?.id_etapa}
        setTarefaModalData={setTarefaModalData}
        showNovaTarefaModal={showNovaTarefaModal[index]}
        tarefaModalData={tarefaModalData}
        refreshEtapas={refreshEtapas}
        setTempEtapas={setTempEtapas}
        tempEtapa={etapa}
        isNewEntityScreen={isNewEntityScreen}
        closeModal={closeModal}
        users={users}
      />
    </>
  )
}
