import React, { useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'
import { FaCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Button, Tooltip } from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { listMyProjects, listProjects } from 'sigap/services/projects'
import { IProject } from 'sigap/types/projects'
import { SearchBar } from 'sigap/utils/generic'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { LoadingScreen } from 'sigap/utils/generic/LoadingScreen/LoadingScreen'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'
import { returnPaginationComponentOptions } from 'sigap/utils/methods/returnPaginationComponentOptions'
import { returnStringFromBasicInfoArray } from 'sigap/utils/methods/returnStringFromBasicInfoArray'

import { ProjectsContainer } from './styles'

const columns: TableColumn<IProject>[] = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    grow: 10
  },
  {
    name: 'PROJETO',
    selector: (row) => row.nome,
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'SUBSECRETARIA',
    selector: (row) => (row.subsecretaria ? returnStringFromBasicInfoArray(row.subsecretaria as IBasicInfo[]) : '-'),
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'RESPONSÁVEL',
    selector: (row) =>
      row.responsavel && row.responsavel.length > 0
        ? returnStringFromBasicInfoArray(row.responsavel as IBasicInfo[])
        : '-',
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'EQUIPE',
    selector: (row) =>
      row.equipe && row.equipe.length > 0 ? returnStringFromBasicInfoArray(row.equipe as IBasicInfo[]) : '-',
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'ODS',
    selector: (row) => (row.ods && row.ods.length > 0 ? returnStringFromBasicInfoArray(row.ods as IBasicInfo[]) : '-'),
    sortable: true,
    grow: 40,
    wrap: true,
    allowOverflow: true
  },
  // {
  //   name: '% METAS ATINGIDAS',
  //   selector: (row) => row.status,
  //   sortable: true,
  //   grow: 10
  // },
  {
    name: 'STATUS',
    selector: (row) => row.status?.desc,
    cell: (row) => (
      <Tooltip label={row.status?.desc ?? 'blah'} aria-label="A tooltip">
        <span>
          <FaCircle color={row.status?.cor ?? '#000000'} />
        </span>
      </Tooltip>
    ),
    grow: 10,
    center: true
  }
]

export const Projects: React.FC = () => {
  const [todosSearchTerm, setTodosSearchTerm] = useState<string>('')
  const [meusSearchTerm, setMeusSearchTerm] = useState<string>('')
  const { loading: listProjectsLoading, data: projects, cancel: cancelListProjects } = useRequest(listProjects)
  const { data: myProjects } = useRequest(listMyProjects)

  const navigate = useNavigate()

  if (listProjectsLoading) {
    return <LoadingScreen />
  }

  return (
    <ProjectsContainer>
      <div className="button-row-container">
        <GenericButton onClick={() => navigate('/projetos/novo')} leftIcon={<BsPlusLg />} colorScheme="green">
          Novo Projeto
        </GenericButton>
      </div>
      <div className="projects-body">
        <div className="table-title meus-projetos">
          <div className="table-title-header">Meus projetos</div>
          <SearchBar setSearchTerm={setMeusSearchTerm} placeholder="Pesquisar meus projetos" />
          <div className="table-container">
            <DataTable
              columns={columns}
              data={myProjects?.filter((proj) => proj.nome.toLowerCase().includes(meusSearchTerm?.toLowerCase()))}
              onRowClicked={(project) => navigate(`/projetos/${project.id}`)}
              dense
              pointerOnHover
              highlightOnHover
              striped
              pagination
              paginationComponentOptions={returnPaginationComponentOptions('Projetos')}
              noDataComponent={<NoDataComponent dataType="projetos" />}
            />
          </div>
        </div>
        <div className="table-title todos-projetos">
          <div className="table-title-header">Todos os projetos</div>
          <SearchBar setSearchTerm={setTodosSearchTerm} placeholder="Pesquisar todos os projetos" />
          <div className="table-container">
            <DataTable
              columns={columns}
              data={projects?.filter((proj) => proj.nome.toLowerCase().includes(todosSearchTerm?.toLowerCase()))}
              dense
              onRowClicked={(project) => navigate(`/projetos/${project.id}`)}
              pointerOnHover
              highlightOnHover
              striped
              pagination
              paginationComponentOptions={returnPaginationComponentOptions('Projetos')}
              noDataComponent={<NoDataComponent dataType="projetos" />}
            />
          </div>
        </div>
      </div>
    </ProjectsContainer>
  )
}
