import styled from 'styled-components'

export const EtapaListContainer = styled.div`
  .etapa-header {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    background: #005a92;
    border-radius: 10px;
    padding: 0 25px;
    display: inline-block;
    height: 100%;
    position: relative;
    z-index: 1;
    margin-left: 35px;
  }

  .etapa-content {
    border-radius: 10px;
    border: 1px solid rgba(151, 151, 151, 0.38);
    background: rgba(217, 217, 217, 0.2);
    transform: translateY(-14px);
    padding: 24px;
  }
`
