import { Modal } from '@chakra-ui/react'
import styled from 'styled-components'

export const ModalContainer = styled(Modal)`
  .nova-meta-modal-content {
    .nova-meta-modal-header {
      color: #000;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
    .nova-meta-modal-body {
      .modal-column {
        display: flex;
        flex-direction: column;
        padding: 0px 48px;
        gap: 24px;
      }
    }
  }
`
