import { SUBSECRETARIA, api } from 'sigap/utils/constants'

export async function listSubsecretarias(): Promise<ISubsecretaria[]> {
  const { data: response } = await api.get(`/${SUBSECRETARIA}`)

  return response
}

export async function getSubsecretaria(subsecretariaId: string): Promise<ISubsecretaria> {
  const { data: response } = await api.get(`/${SUBSECRETARIA}/${subsecretariaId}`)

  return response
}

export async function insertSubsecretaria(subsecretaria: ISubsecretaria): Promise<ISubsecretaria> {
  const { data: response } = await api.post(`/${SUBSECRETARIA}/`, subsecretaria)

  return response
}

export async function editSubsecretaria(
  subsecretaria: ISubsecretaria,
  subsecretariaId: number
): Promise<ISubsecretaria> {
  const { data: response } = await api.patch(`/${SUBSECRETARIA}/${subsecretariaId}`, subsecretaria)

  return response
}

export async function getAreaFromSubsecretaria(subsecretariaId: string): Promise<IArea[]> {
  const { data: response } = await api.get(`/${SUBSECRETARIA}/${subsecretariaId}/area`)

  return response
}
