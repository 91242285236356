import { IIndicador } from 'sigap/types/projects'
import { INDICADORES, PROJETOS, api } from 'sigap/utils/constants'

export interface IIndicadoresDto {
  indicadores: IIndicador[]
}

export async function insertIndicadores(indicadores: IIndicadoresDto, projectId: number): Promise<IMeta> {
  const { data: response } = await api.post(`/${INDICADORES}/${projectId}`, indicadores)

  return response
}

export async function editIndicadores(indicadores: IIndicadoresDto, projectId: number): Promise<IMeta> {
  const { data: response } = await api.patch(`/${INDICADORES}/${PROJETOS}/${projectId}`, indicadores)

  return response
}

export async function getIndicadoresByProject(projectId: number): Promise<IIndicador[]> {
  const { data: response } = await api.get(`/${INDICADORES}/${PROJETOS}/${projectId}`)

  return response
}
