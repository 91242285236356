import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Controller, useForm } from 'react-hook-form'

import { Button, Textarea } from '@chakra-ui/react'
import moment from 'moment'

import { getCommentsOnContrato, insertCommentOnContrato } from 'sigap/services/contracts'
import { getCommentsOnDemanda, insertCommentOnDemanda } from 'sigap/services/demandas'
import { getCommentsOnProduto, insertCommentOnProduto } from 'sigap/services/produtos'
import { getCommentsOnProject, insertCommentOnProject } from 'sigap/services/projects'
import { getCommentsOnTarefa, insertCommentOnTarefa } from 'sigap/services/tarefas'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import { CommentChatContainer } from './styles'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { RiDeleteBinLine } from 'react-icons/ri'

const formDefaultValues: IFormData = {
  comment: ''
}

interface IFormData {
  comment: string
}

interface IProps {
  comments: IComment[]
  entityId: number
  type: 'PROJETO' | 'CONTRATO' | 'DEMANDA' | 'TAREFA' | 'PRODUTO'
}

export const CommentChat: React.FC<IProps> = ({ comments, entityId, type }) => {
  const [usedComments, setUsedComments] = useState<IComment[]>(comments)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const SwalAlert = withReactContent(Swal)
  const { handleSubmit, control, setValue } = useForm<IFormData>({
    mode: 'all',
    defaultValues: formDefaultValues
  })

  const handleSendComment = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      if (formObject.comment === '') {
        SwalAlert.fire({
          toast: true,
          position: 'bottom',
          icon: 'warning',
          title: 'Comentario nao pode ser vazio.',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
        return
      }
      if (type === 'PROJETO') {
        await insertCommentOnProject({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnProject(entityId)
        setUsedComments(newComments)
      }
      if (type === 'CONTRATO') {
        await insertCommentOnContrato({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnContrato(entityId)
        setUsedComments(newComments)
      }
      if (type === 'DEMANDA') {
        await insertCommentOnDemanda({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnDemanda(entityId)
        setUsedComments(newComments)
      }
      if (type === 'TAREFA') {
        await insertCommentOnTarefa({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnTarefa(entityId)
        setUsedComments(newComments)
      }
      if (type === 'PRODUTO') {
        await insertCommentOnProduto({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnProduto(entityId)
        setUsedComments(newComments)
      }

      SwalAlert.fire({
        toast: true,
        position: 'bottom',
        icon: 'success',
        title: 'Comentario inserido.',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
      })
      setValue('comment', '')
    } catch (error) {
      console.log(error)
    } finally {
      setButtonLoading(false)
    }
  }

  const deleteComment = async (commentId: number): Promise<void> => {
    console.log(commentId)
  }

  useEffect(() => {
    setUsedComments(comments)
  }, [comments])

  return (
    <CommentChatContainer>
      <Tabs>
        <div className="comments-header">
          <TabList className="tab-list-admin">
            <Tab sx={{ _selected: { textDecoration: 'underline', color: 'black', fontWeight: 'bold' } }}>
              COMENTÁRIOS
            </Tab>
            <Tab sx={{ _selected: { textDecoration: 'underline', color: 'black', fontWeight: 'bold' } }}>Histórico</Tab>
          </TabList>
        </div>

        <div className="comment-list">
          <TabPanels>
            <TabPanel>
              <div className="comment-input-container" style={{ marginBottom: '20px', marginTop: '-50px' }}>
                <Controller name="comment" control={control} render={({ field }) => <Textarea {...field} />} />
                <Button onClick={handleSubmit(handleSendComment)} isLoading={buttonLoading}>
                  Enviar comentário
                </Button>
              </div>
              {usedComments != null ? (
                usedComments
                  ?.sort((a, b) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                  })
                  ?.filter((comment) => comment.is_log === false || comment.is_log === null)
                  .map((comment, index) => {
                    return (
                      <div key={index} className="comment-card">
                        <div className="identification-row">
                          <Avatar name={comment.user_name} size="50" round />
                          <div className="name-time-column">
                            <div>{comment.user_name}</div>
                            <div className="date-time">
                              {moment(new Date(comment.created_at)).format('DD/MM/YYYY, HH:mm')}
                            </div>
                          </div>
                          {/* <div className="delete-comment" onClick={() => deleteComment(comment.id)} title="Excluir comentario" style={{ marginLeft: 'auto', color: 'red', cursor: 'pointer' }}>
                        <RiDeleteBinLine/>
                    </div> */}
                        </div>
                        <div className="text-row">{comment.comentario}</div>
                      </div>
                    )
                  })
              ) : (
                <div>Não há comentários.</div>
              )}
            </TabPanel>
            <TabPanel>
              {usedComments != null ? (
                usedComments
                  ?.sort((a, b) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                  })
                  ?.filter((comment) => comment.is_log === true)
                  .map((comment, index) => {
                    return (
                      <div key={index} className="comment-card">
                        <div className="identification-row">
                          <Avatar name={comment.user_name} size="50" round />
                          <div className="name-time-column">
                            <div>{comment.user_name}</div>
                            <div className="date-time">
                              {moment(new Date(comment.created_at)).format('DD/MM/YYYY, HH:mm')}
                            </div>
                          </div>
                          {/* <div className="delete-comment" onClick={() => deleteComment(comment.id)} title="Excluir comentario" style={{ marginLeft: 'auto', color: 'red', cursor: 'pointer' }}>
                        <RiDeleteBinLine/>
                    </div> */}
                        </div>
                        <div className="text-row">{comment.comentario}</div>
                      </div>
                    )
                  })
              ) : (
                <div>Sem histórico.</div>
              )}
            </TabPanel>
          </TabPanels>
        </div>
      </Tabs>
    </CommentChatContainer>
  )
}
