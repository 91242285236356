import React, { createContext } from 'react'

declare interface IAuthenticationContext {
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  currentUser: IUser
  setCurrentUser: React.Dispatch<React.SetStateAction<IUser>>
}

export const AuthenticationContext = createContext({} as IAuthenticationContext)
