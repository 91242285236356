import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ChakraProvider } from '@chakra-ui/react'
import { useRequest } from 'ahooks'
import { ThemeProvider } from 'styled-components'

import { View } from 'sigap/components/View'

import { SideBar } from './components/SideBar/SideBar'
import { AuthenticationContext } from './context/AuthenticationContext'
import { me } from './services/users'
import { GlobalTheme, chakraOverwriteTheme, theme } from './styles'
import { LoadingScreen } from './utils/generic/LoadingScreen/LoadingScreen'

export const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<IUser>()

  const { loading: authLoading, error: authError, data: user } = useRequest(me)

  useEffect(() => {
    if (authError) {
      setIsAuthenticated(false)
    }
  }, [authError])

  useEffect(() => {
    if (!authError && !authLoading) {
      setIsAuthenticated(true)
    }
  }, [authError, authLoading])

  useEffect(() => {
    if (user) {
      setCurrentUser(user)
    }
  }, [user])

  if (authLoading) {
    return (
      <ChakraProvider
        theme={chakraOverwriteTheme}
        toastOptions={{ defaultOptions: { position: 'bottom', duration: 5000, isClosable: true } }}
      >
        <LoadingScreen />
      </ChakraProvider>
    )
  }

  return (
    <AuthenticationContext.Provider value={{ isAuthenticated, setIsAuthenticated, currentUser, setCurrentUser }}>
      <ChakraProvider
        theme={chakraOverwriteTheme}
        toastOptions={{ defaultOptions: { position: 'bottom', duration: 5000, isClosable: true } }}
      >
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {isAuthenticated && <SideBar />}
            <View />
            <GlobalTheme />
          </BrowserRouter>
        </ThemeProvider>
      </ChakraProvider>
    </AuthenticationContext.Provider>
  )
}
