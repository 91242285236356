import React, { useState } from 'react'
import { TableColumn } from 'react-data-table-component'

import { useRequest } from 'ahooks'

import { listOds } from 'sigap/services/ods'

import { GenericAdminListTab } from '../GenericAdminListTab/GenericAdminListTab'
import { AdminContainer } from '../styles'

const odsColumns: TableColumn<IOds>[] = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    grow: 10
  },
  {
    name: 'NOME',
    selector: (row) => row.nome,
    sortable: true,
    grow: 40,
    wrap: true
  }
]

export const ODSList: React.FC = () => {
  const [showODSModal, setShowODSModal] = useState<boolean>(false)
  const [odsModalData, setODSModalData] = useState<IOds>(null)

  const { data: ods, loading: odsLoading } = useRequest(listOds)

  const handleShowModal = (ods?: IOds): void => {
    setShowODSModal(true)
    if (ods != null) {
      setODSModalData(ods)
    }
  }

  return (
    <>
      <AdminContainer>
        <GenericAdminListTab
          columns={odsColumns}
          data={ods}
          title="ODS"
          loading={odsLoading}
          onRowClick={handleShowModal}
          onAddClick={() => handleShowModal()}
          dataType="ODS"
        />
      </AdminContainer>
    </>
  )
}
