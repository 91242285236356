import React from 'react'

import { Alert, AlertIcon } from '@chakra-ui/react'

export const PendingAlert: React.FC = () => {
  return (
    <Alert status="info" width={'auto'} marginBottom={'36px'}>
      <AlertIcon />
      Seu projeto está pendente de aprovação.
    </Alert>
  )
}
