import { IProduto } from 'sigap/types/contracts'
import { CONTRATO, CONTRATOS, PRODUTOS, USER, api, cancelToken } from 'sigap/utils/constants'

export interface IProdutosDto {
  produtos: IProduto[]
}

export async function listProdutos(): Promise<IProduto[]> {
  const { data: response } = await api.get(`/${PRODUTOS}`)

  return response
}

export async function getProduto(produtoId: number): Promise<IProduto> {
  const { data: response } = await api.get(`/${PRODUTOS}/${produtoId}`)

  return response
}

export async function editProdutoOnContract(produtos: IProdutosDto, contratoId: number): Promise<IProduto> {
  const { data: response } = await api.patch(`/${PRODUTOS}/${contratoId}`, produtos)

  return response
}

export async function insertProdutoOnContract(produtos: IProdutosDto, contratoId: number): Promise<IProduto> {
  const { data: response } = await api.post(`/${PRODUTOS}/${contratoId}`, produtos)

  return response
}

export async function listProdutosByContrato(contratoId: number): Promise<IProduto[]> {
  const { data: response } = await api.get(`/${PRODUTOS}/${CONTRATO}/${contratoId}`)

  return response
}

export async function getProdutoByUser(userId: number): Promise<IProduto[]> {
  const { data: response } = await api.get(`/${PRODUTOS}/${USER}/${userId}`, { cancelToken: cancelToken.token })

  return response
}

export async function insertCommentOnProduto(comment: IComment, produtoId: number): Promise<{ id_projeto: number }> {
  const { data: response } = await api.post(`/${PRODUTOS}/${produtoId}/comentarios`, comment)

  return response
}

export async function getCommentsOnProduto(produtoId: number): Promise<IComment[]> {
  const { data: response } = await api.get(`/${PRODUTOS}/${produtoId}/comentarios`)

  return response
}
