import React, { useState } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { BsPlusLg, BsThreeDots } from 'react-icons/bs'

import { Button, CardBody, CardHeader } from '@chakra-ui/react'

import { ITarefa } from 'sigap/types/tarefas'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'

import { ColumnContainer } from './styles'
import { TaskCard } from './TaskCard'

interface IProps {
  columnData: IColumn
  searchTerm: string
  tarefaMapByStatus: Map<number, ITarefa[]>
  setTarefaModalData: React.Dispatch<React.SetStateAction<ITarefa>>
  setShowNovaTarefaModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const Column: React.FC<IProps> = ({
  columnData,
  tarefaMapByStatus,
  searchTerm,
  setTarefaModalData,
  setShowNovaTarefaModal
}) => {
  const handleShowNovaTarefaModal = (): void => {
    setShowNovaTarefaModal(true)
  }

  const handleSetTarefaModalWithData = (tarefa: ITarefa): void => {
    setTarefaModalData(tarefa)
    setShowNovaTarefaModal(true)
  }

  return (
    <ColumnContainer>
      <CardHeader className="column-header" backgroundColor={columnData.color} color={'#242731'}>
        <div className="column-header-first-row">
          <div className="column-header-name">{columnData.name}</div>
          <div className="first-row-counter-menu-bundle">
            <div className="column-header-counter">{tarefaMapByStatus?.get(columnData.id)?.length ?? 0}</div>
            <div className="column-header-menu-button">{/* <BsThreeDots /> */}</div>
          </div>
        </div>
        <div className="column-header-secont-row">
          <GenericButton
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            gap={'20px'}
            onClick={handleShowNovaTarefaModal}
          >
            <BsPlusLg /> Adicionar Tarefa
          </GenericButton>
        </div>
      </CardHeader>
      <Droppable droppableId={columnData.id.toString()}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <CardBody overflowY={'scroll'} height={'100vh'}>
              {tarefaMapByStatus
                ?.get(columnData.id)
                ?.filter((tarefa) => tarefa.titulo.toLowerCase().includes(searchTerm.toLowerCase()))
                .sort((a, b) => {
                  const dateA = new Date(addThreeDigits(a.data_acao)).getTime()
                  const dateB = new Date(addThreeDigits(b.data_acao)).getTime()

                  return dateB - dateA // Ascending order; use dateB - dateA for descending order
                })
                .map((tarefa, index) => (
                  <Draggable key={tarefa.id_tarefa} draggableId={tarefa.id_tarefa.toString()} index={index}>
                    {(provided) => (
                      <div
                        onClick={() => handleSetTarefaModalWithData(tarefa)}
                        className="ref-div"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TaskCard tarefa={tarefa} />
                      </div>
                    )}
                  </Draggable>
                ))}
            </CardBody>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </ColumnContainer>
  )
}
