import styled from 'styled-components'

export const FullyApprovedProjecteContainer = styled.div`
  padding: 0 41px 0px 21px;
  .ficha-sub-body {
    color: #414141;
    -webkit-print-color-adjust: exact;
  }
      
  .dados-basicos-final{
    margin-top:30px;
    -webkit-print-color-adjust: exact;
  }

  .dados-basicos-final > div {
    margin-top: 20px;
    -webkit-print-color-adjust: exact;
  }
  .approved-project-header {
    color: #242731;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    padding-left: 40px;
    padding-top: 26px;
    display: flex;
    justify-content: space-between;
    -webkit-print-color-adjust: exact;

    .edit-button-row {
      display: flex;
      gap: 10px;
    }
  }

  .approved-project-body {
    padding-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 60px;
    -webkit-print-color-adjust: exact;


    .body-basic-data {
      display: grid;
      grid-template-columns: 0.75fr 0.25fr;
      gap: 75px;
      position: relative;
      z-index: 2;

      .large-card-info-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 75px;
      }

      .label-info-bundle {
        margin-bottom: 20px;
      }
    }
  }

  @media print {
    .noprint{
      display: none !important;
    }
  }

`
