import { PaginationOptions } from 'react-data-table-component'

export const returnPaginationComponentOptions = (t: string): PaginationOptions => {
  return {
    rowsPerPageText: `${t} por página`,
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
  }
}
