import styled from 'styled-components'

export const AuthenticationScreenContainer = styled.div`
  display: grid;
  grid-template-columns: 0.33fr 0.66fr;

  .auth-header {
    font-weight: 800;
    font-size: 40px;
    color: var(--chakra-colors-gray-1000);
    padding-bottom: 68px;
  }

  .auth-page {
    width: 70vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .auth-main-container {
      width: 502px;
      .auth-header {
        font-weight: 800;
        font-size: 40px;
        color: var(--chakra-colors-gray-1000);
        padding-bottom: 68px;
      }
      .form-control-container {
        display: flex;
        flex-direction: column;
        gap: 58px;
        margin-bottom: 72px;
      }
      .bottom-link-container {
        display: flex;
        justify-content: space-between;
        .forgot-password,
        .new-user {
          color: var(--chakra-colors-green-500);
          text-decoration: underline;
          margin-top: 8px;
          cursor: pointer;
        }
        .go-back-login {
          display: flex;
          margin-top: 8px;
          gap: 16px;
          > span {
            color: var(--chakra-colors-green-500);
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
`

export const NoAuthSideBarContainer = styled.div`
  height: 100vh;
  width: 30vw;
  background-color: #001b37;
  position: relative;

  .side-bar-sigest-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 72px;
  }

  .side-bar-secretaria-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-bottom: 40px;
  }
`
