import React, { useState, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  Button,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import {
  editTarefa,
  editTarefasOnProject,
  getCommentsOnTarefa,
  insertTarefasOnContrato,
  insertTarefasOnDemanda,
  insertTarefasOnProject
} from 'sigap/services/tarefas'
import { listUsers } from 'sigap/services/users'
import { IEtapa, ITarefa } from 'sigap/types/tarefas'
import { TAREFA_CREATE_TOAST_MESSAGE, TAREFA_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { CommentChat } from 'sigap/utils/generic/CommentChat/CommentChat'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'
import { SingleDatePicker } from 'sigap/utils/generic/SingleDatePicker/SingleDatePicker'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { returnBasicInfoArrayFromOptionArray } from 'sigap/utils/methods/returnBasicInfoArrayFromOptionArray'
import { returnNumberValueArrayFromMultiSelect } from 'sigap/utils/methods/returnNumberValueArrayFromMultiSelect'

interface IFormData {
  nome: string
  descricao: string
}

interface IProps {
  showNovaEtapaModal: boolean
  setShowNovaEtapaModal: (k: boolean) => void
  entityId: number
  type: 'PROJETO' | 'CONTRATO' | 'DEMANDA'
  setEtapaModalData: React.Dispatch<React.SetStateAction<IEtapa>>
  etapaModalData?: IEtapa
  refreshEtapas: () => Promise<IEtapa[]>
  setTempEtapas?: React.Dispatch<React.SetStateAction<IEtapa[]>>
  tempEtapas?: IEtapa[]
  isNewEntityScreen?: boolean
}

export const ModalNovaEtapa: React.FC<IProps> = ({
  showNovaEtapaModal,
  setShowNovaEtapaModal,
  entityId,
  type,
  etapaModalData,
  setEtapaModalData,
  refreshEtapas,
  tempEtapas,
  setTempEtapas,
  isNewEntityScreen
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()
  const [count, setCount] = useState<number>(1)

  const currentFormData = watch()

  const toast = useToast()

  const isFormComplete = useMemo(
    () => currentFormData.nome?.length > 0 && currentFormData.descricao?.length,
    [currentFormData]
  )

  const handleCleanForm = (): void => {
    setValue('nome', '')
    setValue('descricao', '')
  }

  const handleCloseNovaEtapaModal = (): void => {
    handleCleanForm()
    setShowNovaEtapaModal(false)
    setEtapaModalData(undefined)
  }

  const normalizeEtapaObject = (formObject: IFormData): IEtapa => {
    if (isFormComplete) {
      const normalizedEtapaObject: IEtapa = {
        nome: formObject.nome,
        descricao: formObject?.descricao,
        tarefas: []

        // id_tarefa: tarefaModalData?.id_tarefa
      }

      return normalizedEtapaObject
    }
  }

  const handleCreateEtapa = async (formObject: IFormData): Promise<void> => {
    const normalizedEtapa = normalizeEtapaObject(formObject)
    if (type === 'DEMANDA') {
      await insertTarefasOnDemanda({ etapas: [normalizedEtapa] }, entityId)
    }
    if (type === 'PROJETO') {
      await insertTarefasOnProject({ etapas: [normalizedEtapa] }, entityId)
    }
    if (type === 'CONTRATO') {
      await insertTarefasOnContrato({ etapas: [normalizedEtapa] }, entityId)
    }
    refreshEtapas()
    handleCloseNovaEtapaModal()
  }

  const normalizeTempEtapaObject = (formObject: IFormData): IEtapa => {
    if (isFormComplete) {
      const normalizedTempEtapaObject: IEtapa = {
        nome: formObject.nome,
        descricao: formObject?.descricao,
        tarefas: [],
        id: count
      }
      setCount(count + 1)

      return normalizedTempEtapaObject
    }
  }

  const handleNovaTempEtapa = (formObject: IFormData): void => {
    const normalizedTempEtapa = normalizeTempEtapaObject(formObject)
    setTempEtapas((current) => [...current, normalizedTempEtapa])
    handleCleanForm()
    setShowNovaEtapaModal(false)
  }

  const handleEditTempEtapa = (formObject: IFormData): void => {
    if (etapaModalData) {
      const normalizedTempEtapa = normalizeTempEtapaObject(formObject)
      const newData: IEtapa[] = tempEtapas.map((etapa) => {
        if (etapa.id === etapaModalData.id) {
          return normalizedTempEtapa
        }

        return etapa
      })
      setTempEtapas(newData)
      handleCloseNovaEtapaModal()
    }
  }

  const handleClickSubmitCreate = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleCreateEtapa(formObject), TAREFA_CREATE_TOAST_MESSAGE)
  }

  const handleEditEtapa = async (formObject: IFormData): Promise<void> => {
    const normalizedEtapa = normalizeEtapaObject(formObject)
    await editTarefa(normalizedEtapa, normalizedEtapa.id)

    refreshEtapas()
    handleCloseNovaEtapaModal()
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleEditEtapa(formObject), TAREFA_EDIT_TOAST_MESSAGE)
  }

  useEffect(() => {
    if (etapaModalData) {
      setValue('nome', etapaModalData.nome)
      setValue('descricao', etapaModalData.descricao)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [etapaModalData])

  return (
    <ModalContainer isOpen={showNovaEtapaModal} onClose={console.log} closeOnOverlayClick={true}>
      <ModalOverlay />
      <ModalContent minWidth={800} className="nova-meta-modal-content">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          className="nova-meta-modal-header"
          color={'gray.900'}
          fontFamily={'Poppins'}
          fontSize={32}
          fontStyle={'normal'}
          fontWeight={700}
          lineHeight={'36px'}
        >
          {etapaModalData ? <>{etapaModalData.nome}</> : <>Nova Etapa</>}
        </ModalHeader>
        <ModalBody
          display={'flex'}
          flexDirection={'column'}
          padding={'0px 48px'}
          gap={'32px'}
          className="nova-meta-modal-body"
        >
          <div className="label-input-bundle">
            <label>Nome da Etapa</label>
            <Controller name="nome" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
          <div className="new-project-label-input-bundle">
            <label>Descrição</label>
            <Controller name="descricao" control={control} render={({ field }) => <Textarea {...field} />} />
          </div>
        </ModalBody>

        <ModalFooter display={'flex'} gap={'80px'}>
          {!isNewEntityScreen ? (
            <>
              {etapaModalData ? (
                <GenericButton onClick={handleSubmit(handleClickSubmitEdit)} isDisabled={!isFormComplete}>
                  Salvar Edição
                </GenericButton>
              ) : (
                <GenericButton onClick={handleSubmit(handleClickSubmitCreate)} isDisabled={!isFormComplete}>
                  Adicionar e Salvar
                </GenericButton>
              )}
            </>
          ) : etapaModalData ? (
            <GenericButton onClick={handleSubmit(handleEditTempEtapa)} isDisabled={!isFormComplete}>
              Salvar Edição
            </GenericButton>
          ) : (
            <GenericButton onClick={handleSubmit(handleNovaTempEtapa)} isDisabled={!isFormComplete}>
              Adicionar
            </GenericButton>
          )}

          <GenericButton variant={'outline'} color={'#242731'} onClick={handleCloseNovaEtapaModal}>
            Cancelar
          </GenericButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
