import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { api } from 'sigap/utils/constants'

interface IProps {
  Component: React.FC
}

export const CustomRoute: React.FC<IProps> = ({ Component }) => {
  const location = useLocation()
  // Detect Route Change
  useEffect(() => {
    return () => {
      api.cancelPendingRequests()
    }
  }, [location?.pathname])

  return <Component />
}
