import styled from 'styled-components'

export const HiddenSideBar = styled.div`
  height: 100vh;
  background-color: #001b37;
  color: var(--chakra-colors-white);

  .hidden-bar-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 8px;

    .hidden-bar-button {
      &:hover {
        color: var(--chakra-colors-blue-800);
        cursor: pointer;
      }

      &.selected {
        color: var(--chakra-colors-blue-200);
      }
    }
  }
`

export const SideBarContainer = styled.div`
  width: 20vw;
  height: 100vh;
  background-color: #001b37;
  position: relative;

  color: var(--chakra-colors-white);

  .collapse-icon-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
    &:hover {
      color: var(--chakra-colors-blue-800);
      cursor: pointer;
    }
  }

  .side-bar-sigest-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 44px;
    padding-bottom: 30px;
  }

  .side-bar-button-container {
    padding-left: 25px;
    padding-bottom: 140px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .side-bar-button {
      cursor: pointer;
      /* font-size: 14px; */
      display: flex;
      gap: 12px;
      align-items: center;
      &.selected {
        color: var(--chakra-colors-blue-200);
      }
    }
  }

  .selected-highlight {
    height: 46px;
    background-color: var(--chakra-colors-blue-800);
    display: flex;
    align-items: center;
    padding-left: 54px;
    font-size: 20px;
  }

  .relatorios-button {
    cursor: pointer;
    /* font-size: 14px; */
    display: flex;
    gap: 12px;
    align-items: center;
    padding-left: 25px;
    margin-top: 32px;

    &.selected {
      color: var(--chakra-colors-blue-200);
    }
  }

  .side-bar-secretaria-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-bottom: 40px;
  }
`
