import { createGlobalStyle } from 'styled-components'

export const GlobalTheme = createGlobalStyle`
:root {
    ${({ theme }) =>
      Object.entries(theme)
        .filter(([_key, value]) => typeof value === 'string')
        .map(([key, value]) => `--${key}: ${value}`)
        .join(';\n') + ';\n'}
            -webkit-print-color-adjust: exact;

  }


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  
}


#root {
  display: flex;
  
}

@media print {
    .noprint {
      display: none !important;
    }
    
    @page {
      size: 11.7in 16.5in ;
      margin: 0;
      
    }
    
    body {
      padding-top: 2cm !important;
    }

  }
`
