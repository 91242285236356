import React from 'react'

import { Progress } from '@chakra-ui/react'

import { LoadingScreenContainer } from './styles'

export const LoadingScreen: React.FC = () => {
  return (
    <LoadingScreenContainer>
      <Progress size="xs" isIndeterminate width={200} />
    </LoadingScreenContainer>
  )
}
