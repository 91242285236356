import { CHART, TAREFAS, api } from 'sigap/utils/constants'

interface IStatusTarefaChartData {
  todo: number
  progress: number
  done: number
}

export async function getStatusTarefasChartData(): Promise<IStatusTarefaChartData[]> {
  const { data: response } = await api.get(`/${CHART}/${TAREFAS}`)

  return response
}
