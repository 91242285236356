import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Switch,
  useToast
} from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { getProfiles } from 'sigap/services/profile'
import { getAreaFromSubsecretaria, listSubsecretarias } from 'sigap/services/subsecretaria'
import { IAuthNewUser, INewUser, newUser } from 'sigap/services/users'
import { USER_CREATE_TOAST_MESSAGE, USER_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'
import {} from '@chakra-ui/react'

interface IFormData {
  nome: string
  email: string
  perfil: Option<number>
  subsecretaria: Option<number>
  area: Option<number>
  is_active: boolean
  send_welcome_email: boolean
}

interface IProps {
  userModalData: IUser
  showUserModal: boolean
  setUserModalData: React.Dispatch<React.SetStateAction<IUser>>
  setShowUserModal: React.Dispatch<React.SetStateAction<boolean>>
  subsecretarias: ISubsecretaria[]
  refreshUsers: () => void
}

export const UserModal: React.FC<IProps> = ({
  userModalData,
  showUserModal,
  setUserModalData,
  setShowUserModal,
  subsecretarias,
  refreshUsers
}) => {
  const [requestLoading, setRequestLoading] = useState<boolean>(false)

  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()

  const currentFormData = watch()

  const toast = useToast()

  const {
    data: areas,
    refreshAsync: refreshArea,
    loading: areaLoading
  } = useRequest(getAreaFromSubsecretaria, {
    defaultParams: [currentFormData?.subsecretaria?.value?.toString()],
    ready: !!currentFormData?.subsecretaria?.value || !!userModalData?.subsecretaria
  })

  const {
    data: profiles,
    loading: profilesLoading,
    error: profilesError
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useRequest(getProfiles)

  const isFormComplete = useMemo(
    () =>
      currentFormData.nome?.length > 0 &&
      currentFormData.email?.length > 0 &&
      currentFormData.perfil != null &&
      currentFormData.subsecretaria != null &&
      currentFormData.area != null,
    [currentFormData]
  )

  const handleClose = (): void => {
    setShowUserModal(false)
    setUserModalData(null)
    setValue('nome', '')
    setValue('email', '')
    setValue('perfil', null)
    setValue('subsecretaria', null)
    setValue('area', null)
  }

  const handleEditUser = async (formObject: IFormData): Promise<void> => {
    setRequestLoading(true)
    const newUserObj: INewUser = {
      area_id: formObject.area.value,
      email: formObject.email,
      nome: formObject.nome,
      perfil_id: formObject.perfil.value
    }
    await newUser(newUserObj)
    setShowUserModal(false)
    refreshUsers()
  }

  const handleCreateUser = async (formObject: IFormData): Promise<void> => {
    setRequestLoading(true)
    const newUserObj: INewUser = {
      area_id: formObject.area.value,
      email: formObject.email,
      nome: formObject.nome,
      perfil_id: formObject.perfil.value
    }
    await newUser(newUserObj)
    setShowUserModal(false)
    refreshUsers()
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleEditUser(formObject), USER_EDIT_TOAST_MESSAGE)
  }

  const handleClickSubmitCreate = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleCreateUser(formObject), USER_CREATE_TOAST_MESSAGE)
  }

  useEffect(() => {
    if (userModalData) {
      setValue('nome', userModalData.nome)
      setValue('email', userModalData.email)
      setValue('perfil', { label: userModalData.perfil, value: userModalData.perfil })
      setValue('subsecretaria', { label: userModalData.subsecretaria, value: userModalData.subsecretaria })
      setValue('area', { label: userModalData.area, value: userModalData.area })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userModalData])

  return (
    <ModalContainer isOpen={showUserModal} onClose={console.log}>
      <ModalOverlay />
      <ModalContent minWidth={800} className="nova-meta-modal-content">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          className="nova-meta-modal-header"
          color={'gray.900'}
          fontFamily={'Poppins'}
          fontSize={32}
          fontStyle={'normal'}
          fontWeight={700}
          lineHeight={'36px'}
        >
          Usuário
        </ModalHeader>
        <ModalBody
          // display={'grid'}
          padding={'0px 48px'}
          // gap={'32px'}
          className="nova-meta-modal-body"
          // gridTemplateColumns={'1fr 1fr'}
        >
          <div className="label-input-bundle">
            <label>Nome</label>
            <Controller name="nome" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
          <div className="label-input-bundle">
            <label>Email</label>
            <Controller name="email" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
          <div className="label-input-bundle">
            <label>Perfil</label>
            <Controller
              name="perfil"
              control={control}
              render={({ field }) => (
                <GenericSelect
                  options={profiles?.map((perfil) => ({ label: perfil.descricao, value: perfil.id }))}
                  {...field}
                />
              )}
            />
          </div>
          <div className="label-input-bundle">
            <label>Sub Sercretaria</label>
            <Controller
              name="subsecretaria"
              control={control}
              render={({ field }) => (
                <GenericSelect
                  placeholder="Subsecretaria"
                  options={subsecretarias?.map((subsecretaria) => ({
                    label: subsecretaria.nome,
                    value: subsecretaria.id
                  }))}
                  {...field}
                />
              )}
            />
          </div>
          <div className="label-input-bundle">
            <label>Área</label>
            <Controller
              name="area"
              control={control}
              render={({ field }) => (
                <GenericSelect
                  placeholder="Área"
                  isDisabled={!areas}
                  options={areas?.map((area) => ({
                    label: area.nome,
                    value: area.id
                  }))}
                  {...field}
                />
              )}
            />
          </div>
          <div className="label-input-bundle" style={{ marginTop: '20px' }}>
            <label style={{ marginLeft: '75%' }} htmlFor="is_active">
              &ensp;&ensp;Usuário ativo?
            </label>
            &ensp;
            <Controller
              name="is_active"
              control={control}
              render={({ field }) => <Switch id="is_active" isChecked={currentFormData.is_active} {...field} />}
            />
          </div>
          <div className="label-input-bundle" style={{ marginTop: '20px', marginBottom: '20px' }}>
            <label style={{ marginLeft: '63%' }} htmlFor="send_welcome_email">
              Enviar e-mail boas-vindas
            </label>
            &ensp;
            <Controller
              name="send_welcome_email"
              control={control}
              render={({ field }) => <Switch id="send_welcome_email" {...field} />}
            />
          </div>
        </ModalBody>
        <ModalFooter display={'flex'} gap={'80px'}>
          {userModalData ? (
            <GenericButton onClick={handleSubmit(handleClickSubmitEdit)} isDisabled={!isFormComplete}>
              Salvar Edição
            </GenericButton>
          ) : (
            <GenericButton onClick={handleSubmit(handleClickSubmitCreate)} isDisabled={!isFormComplete}>
              Adicionar e Salvar
            </GenericButton>
          )}

          <GenericButton variant={'outline'} onClick={handleClose} color={'#242731'}>
            Cancelar
          </GenericButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
