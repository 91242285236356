import React, { useState } from 'react'
import { TableColumn } from 'react-data-table-component'

import { useRequest } from 'ahooks'

import { listSedeics } from 'sigap/services/sedeics'

import { GenericAdminListTab } from '../GenericAdminListTab/GenericAdminListTab'
import { AdminContainer } from '../styles'
import { SedeicsModal } from './SedeicsModal/SedeicsModal'

const sedeicsColumns: TableColumn<ISedeics>[] = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    grow: 10
  },
  {
    name: 'NOME',
    selector: (row) => row.nome,
    sortable: true,
    grow: 40,
    wrap: true
  }
]

export const SedeicsList: React.FC = () => {
  const [showSedeicsModal, setShowSedeicsModal] = useState<boolean>(false)
  const [sedeicsModalData, setSedeicsModalData] = useState<ISedeics>(null)

  const { data: sedeics, loading: sedeicsLoading, refresh: refreshSedeics } = useRequest(listSedeics)

  const handleShowModal = (sedeics?: ISedeics): void => {
    setShowSedeicsModal(true)
    if (sedeics != null) {
      setSedeicsModalData(sedeics)
    }
  }

  return (
    <>
      <AdminContainer>
        <GenericAdminListTab
          columns={sedeicsColumns}
          data={sedeics}
          title="Missões SEDEICS"
          loading={sedeicsLoading}
          onRowClick={handleShowModal}
          onAddClick={() => handleShowModal()}
          dataType="missão SEDEICS"
        />
      </AdminContainer>
      <SedeicsModal
        refreshSedeics={refreshSedeics}
        setShowSedeicsModal={setShowSedeicsModal}
        setSedeicsModalData={setSedeicsModalData}
        sedeicsModalData={sedeicsModalData}
        showSedeicsModal={showSedeicsModal}
      />
    </>
  )
}
