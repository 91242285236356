import React from 'react'

import { RangeDatepicker } from 'chakra-dayzed-datepicker'

interface IProps {
  selectedDates: Date[]
  onDateChange: (dates: Date[]) => void
  disabled?: boolean
}

export const DuracaoPicker: React.FC<IProps> = ({ selectedDates, onDateChange, disabled }) => {
  return (
    <RangeDatepicker
      selectedDates={selectedDates}
      onDateChange={onDateChange}
      configs={{ dateFormat: 'dd/MM/yy' }}
      disabled={disabled}
      propsConfigs={{
        dayOfMonthBtnProps: {
          defaultBtnProps: {
            _hover: {
              backgroundColor: '#91d8f7'
            }
          },
          isInRangeBtnProps: { backgroundColor: '#24aae1' },
          todayBtnProps: { backgroundColor: '#24aae1' },
          selectedBtnProps: { backgroundColor: '#24aae1' }
        }
      }}
    />
  )
}
