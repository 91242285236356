import { extendTheme } from '@chakra-ui/react'

import { colors } from 'sigap/utils/constants'

export const theme = {}

export const chakraOverwriteTheme = extendTheme({
  fonts: { body: "'Poppins', sans-serif", heading: "'Poppins', sans-serif" },
  colors: {
    ...Object.entries(colors).reduce((acc, [key, value]) => {
      if (key.includes('-')) {
        const [color, shade] = key.split('-')
        acc[color] = acc[color] || {}
        acc[color][shade] = value
      } else {
        acc[key] = value
      }

      return acc
    }, {})
  }
})
