import styled from 'styled-components'

export const ContractEditorContainer = styled.div`
  padding: 0 41px 0 21px;
  .contract-editor-header {
    color: #242731;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    padding-left: 85px;
    padding-top: 26px;

    display: flex;
    justify-content: space-between;
  }

  .contrato-editor-body {
    .contrato-body-basic-data {
      display: grid;
      grid-template-columns: 0.75fr 0.25fr;
      gap: 75px;
      padding: 55px 41px 0 21px;
      position: relative;
      z-index: 2;
    }
    .large-card-info-body {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 75px;
    }
    .contrato-editor-list-container {
      padding: 55px 41px 0 21px;
      display: flex;
      flex-direction: column;
    }
  }
`
