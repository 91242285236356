import { Card } from '@chakra-ui/react'
import styled from 'styled-components'

export const LargeFormCard = styled(Card)`
  border-radius: 10px;
  .large-card-form {
    display: flex;
    flex-direction: column;
    gap: 37px;
    padding: 24px;

    .large-card-first-row {
      display: flex;
      justify-content: space-between;
      gap: 100px;
    }

    .large-card-second-row {
      display: flex;
      justify-content: space-between;
      gap: 100px;
    }
  }
`

export const SmallFormCard = styled(Card)`
  width: 100%;
  border-radius: 10px;

  .small-card-form {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 37px;
  }
  .label-input-bundle {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .label-info-bundle {
    .project-info {
      color: var(--chakra-colors-gray-1000);
    }
  }
`
