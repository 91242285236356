import React, { useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { AuthenticationContext } from 'sigap/context/AuthenticationContext'
import { sideBarSecretariaLogoNoAuth, sideBarSigestLogoNoAuth } from 'sigap/resources/images'
import { storageService } from 'sigap/services/storageService'
import { IAuthNewUser, IAuthUser, IPassword, IResetPassword, login, resetPassword, signUp } from 'sigap/services/users'
import { api } from 'sigap/utils/constants'
import { ACCESS_TOKEN } from 'sigap/utils/constants/localStorage'

import { DefaultLogin } from './DefaultLogin/DefaultLogin'
import { ForgotPassword } from './ForgotPassword/ForgotPassword'
import { NewUser } from './NewUser/NewUser'
import { ResetPassword } from './ResetPassword/ResetPassword'
import { AuthenticationScreenContainer, NoAuthSideBarContainer } from './styles'

export const Authentication: React.FC = () => {
  const [authPage, setAuthPage] = useState<'DEFAULT' | 'NEW' | 'FORGOT' | 'RESET'>('DEFAULT')

  const navigate = useNavigate()

  const authenticationContext = useContext(AuthenticationContext)
  const { token } = useParams()

  const handleLogin = async (user: IAuthUser): Promise<void> => {
    const accessToken = await login(user)
    storageService.setItem(ACCESS_TOKEN, accessToken.access_token)

    api.updateAccessToken(accessToken.access_token)

    authenticationContext.setIsAuthenticated(true)
  }

  const location = useLocation()

  const handleNewUser = async (newUser: IAuthNewUser): Promise<void> => {
    await signUp(newUser)
  }

  const handleGoToDefaultAuth = (): void => {
    setAuthPage('DEFAULT')
  }

  const handleResetPassword = async (resetPasswordForm: IResetPassword, token: string): Promise<void> => {
    const passwordObj: IPassword = {
      password: resetPasswordForm.password
    }
    await resetPassword(passwordObj, token)
    handleGoToDefaultAuth()
  }

  const handleGoToForgotPassword = (): void => {
    setAuthPage('FORGOT')
  }

  const handleGoToNewUser = (): void => {
    setAuthPage('NEW')
  }

  useEffect(() => {
    if (authenticationContext.isAuthenticated) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationContext.isAuthenticated])

  useEffect(() => {
    if (token) {
      setAuthPage('RESET')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <AuthenticationScreenContainer>
      <NoAuthSideBarContainer>
        <div className="side-bar-sigest-logo-container">
          <img src={sideBarSigestLogoNoAuth} alt="Logo Sigest barra lateral" />
        </div>
        <div className="side-bar-secretaria-logo-container">
          <img src={sideBarSecretariaLogoNoAuth} alt="Logo Secretaria barra lateral" />
        </div>
      </NoAuthSideBarContainer>
      <div className="auth-page">
        <div className="auth-main-container">
          {authPage === 'RESET' ? (
            <ResetPassword token={token} handleResetPassword={handleResetPassword} />
          ) : authPage === 'DEFAULT' ? (
            <DefaultLogin
              handleLogin={handleLogin}
              handleGoToForgotPassword={handleGoToForgotPassword}
              handleGoToNewUser={handleGoToNewUser}
            />
          ) : authPage === 'FORGOT' ? (
            <ForgotPassword handleGoToDefaultAuth={handleGoToDefaultAuth} />
          ) : (
            <NewUser handleGoToDefaultAuth={handleGoToDefaultAuth} handleNewUser={handleNewUser} />
          )}
        </div>
      </div>
    </AuthenticationScreenContainer>
  )
}
