import { Card } from '@chakra-ui/react'
import styled from 'styled-components'

export const ColumnContainer = styled(Card)`
  min-height: 100vh;
  width: 100%;
  border: 1px solid black !important;

  > .chakra-card__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* border: 1px solid black; */
  }

  > .column-header {
    border-radius: 0.375rem 0.375rem 0 0;
    /* border: 1px solid black; */

    .column-header-first-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .first-row-counter-menu-bundle {
        display: flex;
        align-items: center;
        gap: 12px;

        .column-header-counter {
          width: 40px;
          background-color: var(--chakra-colors-gray-300);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.8rem;
        }

        .column-header-menu-button {
          font-size: 28px;
        }
      }
    }

    .column-header-secont-row {
    }
  }
`
