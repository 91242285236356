import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BsEmojiHeartEyes } from 'react-icons/bs'
import { FaCheck, FaRegEdit, FaRegSave } from 'react-icons/fa'
import { MdOutlineAnnouncement } from 'react-icons/md'
import { RiDeleteBinLine } from 'react-icons/ri'

import { Checkbox, Input, InputGroup, InputRightAddon } from '@chakra-ui/react'

import { deleteSubtarefa, editSubtarefa, insertSubtarefa } from 'sigap/services/tarefas'
import { ISubtarefa } from 'sigap/types/tarefas'

import { SubtarefaInputContainer } from './styles'
import CustomCheckbox from './CustomCheckbox/CustomCheckbox'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

interface IProps {
  subtarefa?: ISubtarefa
  tarefaId: number
  refreshSubtarefas?: () => void
}

export const SubtarefaInput: React.FC<IProps> = ({ subtarefa, tarefaId, refreshSubtarefas }) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const { handleSubmit, control, setValue, watch } = useForm<ISubtarefa>()

  const SwalAlert = withReactContent(Swal)

  const handleUpdate = async (subtarefaForm: ISubtarefa): Promise<void> => {
    try {
      const response = await editSubtarefa(subtarefaForm, subtarefa.id)
      console.log(response)
      if (response.status === 201) {
        // If successful, show success message
        SwalAlert.fire({
          toast: true,
          position: 'bottom',
          icon: 'success',
          title: 'Registro atualizado!',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddSubtarefa = async (subtarefa: ISubtarefa): Promise<void> => {
    try {
      const hasEmptyName = !subtarefa.nome || subtarefa.nome.trim() === ''

      if (hasEmptyName) {
        SwalAlert.fire({
          toast: true,
          position: 'bottom',
          icon: 'warning',
          title: 'Nome da subtarefa não pode ser vazio.',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
        return
      }
      await insertSubtarefa(subtarefa, tarefaId)
      refreshSubtarefas()
      setValue('concluida', false)
      setValue('nome', '')
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteSubtarefa = async (): Promise<void> => {
    try {
      SwalAlert.fire({
        title: 'Tem certeza que deseja excluir?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        // cancelButtonColor: '#3085d6',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir!'
      }).then(async (result) => {
        // implementar delete request
        if (result.isConfirmed) {
          try {
            const response = await deleteSubtarefa(subtarefa.id)

            if (response.status === 201) {
              // If successful, show success message
              SwalAlert.fire({
                toast: true,
                position: 'bottom',
                icon: 'success',
                title: 'Registro excluído!',
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true
              })
            } else {
              console.log('200')
              SwalAlert.fire({
                toast: true,
                position: 'bottom',
                icon: 'error',
                title: 'Algo deu errado.',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true
              })
            }
            refreshSubtarefas()
          } catch (error) {
            SwalAlert.fire({
              toast: true,
              position: 'bottom',
              icon: 'error',
              title: 'Erro no servidor.',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true
            })
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (subtarefa) {
      setValue('concluida', subtarefa.concluida)
      setValue('nome', subtarefa.nome)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtarefa])

  return (
    <SubtarefaInputContainer>
      <Controller
        name="concluida"
        control={control}
        render={({ field }) => (
          //TODO: DEBUG THIS!!
          // <CustomCheckbox
          //   id={subtarefa?.id?.toString()}
          //   field={field}
          //   subtarefa={subtarefa}
          //   handleSubmit={handleSubmit}
          //   handleUpdate={handleUpdate}
          // />
          <Checkbox
            borderColor={'black'}
            {...field}
            isChecked={field.value}
            onChange={(e) => {
              field.onChange(e.target.checked)
              subtarefa && handleSubmit(handleUpdate)()
            }}
          />
        )}
      />
      <InputGroup>
        <Controller
          name="nome"
          control={control}
          render={({ field }) => (
            <Input
              onBlur={subtarefa && handleSubmit(handleUpdate)}
              isDisabled={subtarefa ? !editMode : false}
              className="subtarefa-input"
              {...field}
              style={{
                borderRight: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                borderColor: 'black'
              }}
            />
          )}
        />
        <InputRightAddon gap={'16px'}>
          {subtarefa ? (
            <>
              {!editMode ? (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    color: '#5a8cde'
                  }}
                  onClick={() => setEditMode((old) => !old)}
                >
                  <FaRegEdit />
                </div>
              ) : (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => setEditMode((old) => !old)}
                >
                  <FaCheck onClick={handleSubmit(handleUpdate)} />
                </div>
              )}

              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  color: 'red'
                }}
                onClick={handleSubmit(handleDeleteSubtarefa)}
              >
                <RiDeleteBinLine />
              </div>
            </>
          ) : (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: '#5fc464'
              }}
              onClick={handleSubmit(handleAddSubtarefa)}
            >
              <FaRegSave />
            </div>
          )}
        </InputRightAddon>
      </InputGroup>
    </SubtarefaInputContainer>
  )
}
