import React, { useState, useEffect } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { PieChartContainer } from './styles'

export interface IPieSeries {
  name: string
  y: number
}

interface IProps {
  title: string
  seriesTitle: string
  series: IPieSeries[]
}

export const PieChart: React.FC<IProps> = ({ series, seriesTitle, title }) => {
  const [options, setOptions] = useState({})

  useEffect(() => {
    setOptions({
      chart: {
        type: 'pie'
      },
      title: {
        text: title
      },
      colors: ['#5c9887', '#862828', '#5b5430'],
      series: [
        {
          name: seriesTitle,
          data: series
        }
      ]
    })
  }, [series, seriesTitle, title])

  return (
    <PieChartContainer>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </PieChartContainer>
  )
}
